import {
  safety,
  agreement,
  peskyCalls,
  accounting,
  ourDream,
  rentOnTime
} from '../../Lib/Images'
import LiveDemoPopup from '../../Components/LiveDemoPopup'

const HEADER_DATA = [
  {
    id: 'banner',
    text: 'About MIMO',
    hilight: false,
    getItem:'',
  },
  {
    id: 'Features_and_pricing',
    text: 'Features & pricing',
    hilight: false,
    getItem:''
  },
  {
    id: 'our_story',
    text: 'Our story',
    hilight: false,
    getItem:''
  },
  {
    id: 'testimonials',
    text: 'Testimonials',
    hilight: false,
    getItem:''
  },
  {
    id: 'demobutton',
    text: '',
    getItem: () => <LiveDemoPopup headerStyle />
  },
  // {
  //   id: 'lilst_your_property',
  //   text: 'List your property',
  //   hilight: true,
  //   url: 'https://www.nestaway.com/welcome/owner'
  // }
]

const BENEFITS_DATA = [
  {
    title: 'RENT ON TIME',
    description:
      'Our online rent collection and follow up systems ensure you don’t have to worry about getting rent on time',
    image: rentOnTime
  },
  {
    title: 'SAFETY GUARANTEED',
    description:
      'With tenant history linked to RentKarma, good tenant behaviour is guaranteed and so is the safety of your property ',
    image: safety
  },
  {
    title: 'No Pesky calls',
    description:
      'From tenant background checks to maintenance requests, do everything online, get rid of those pesky calls',
    image: peskyCalls
  },
  {
    title: 'EASY ACCOUNTING',
    description:
      'All expenses and revenue reporting in one place, export reports to major accounting software',
    image: accounting
  },
  {
    title: 'NO LOST AGREEMENTS',
    description:
      'Unlimited document storage with E-agreements trusted by over 100K customers',
    image: agreement
  },
  {
    title: '',
    description: 'Easy living for everyone with no hidden fees',
    image: ourDream
  }
]

export { BENEFITS_DATA, HEADER_DATA }
