/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import { success } from '../../Lib/Images'
import HeaderTab from '../../Components/HeaderTab'

const Content = () => {
  return (
    <article css={s.wrapper}>
      <img src={success} style={s.image} />
      <article css={s.title}>
        Congratulations, your bank account has been linked
      </article>
      <article css={s.description}>
        You can now proceed to add invoice and collect rent from your tenants
      </article>
    </article>
  )
}

const StripeSuccessView = props => {
  return (
    <article css={s.container}>
      <HeaderTab style={s.header}/>
      <Content />
    </article>
  )
}

export default StripeSuccessView

/* Documentation Section */

StripeSuccessView.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

StripeSuccessView.defaultProps = {}
