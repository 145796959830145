const styles = {
  container: alignTop => {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: alignTop ? 'flex-start' : 'flex-end',
      marginLeft: 5,
      marginRight: 5,
      width:300,
      '@media(max-width: 980px)': {
        width: '100%',
        justifyContent: 'flex-end'
      }
    }
  },
  wrappper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: 17,
    paddingRight: 13,
    minWidth: 288,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e7e7e7',
    background: '#ffffff',
    justifyContent: 'flex-end',
    '@media(max-width: 980px)': {
      width: '100%'
    }
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  profileIcon: {
    display: 'flex',
    height: 28,
    width: 28,
    marginRight: 11,
    borderRadius: 28 / 2
    // background: 'yellow'
  },
  profileWrapper: {
    flex: 1,
    display: 'flex'
  },
  profileTextWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  title: {
    display: 'flex',
    fontSize: 14,
    color: '#282828',
    '@media(max-width: 980px)': {}
  },
  description: {
    flex: 1,
    fontSize: 10,
    color: '#a0a0a0',
    marginTop: 3
  },
  socialIcon: {
    display: 'flex',
    height: 17,
    width: 17,
    // background: 'green',
    alignSelft: 'flex-start'
  },
  message: {
    fonotSize: 16,
    lineHeight: '23px',
    color: '#282828',
    marginTop: 26
  }
}

export default styles
