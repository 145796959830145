import { bold } from "ansi-colors";

const styles = {
  container: {
    flex: 1,
    maxWidth: 1060,
    margin: '0 auto',
    paddingTop: 104,
    '@media(max-width: 980px)': {
      marginLeft: 16,
      marginRight: 10,
      paddingTop: 60
    }
  },
  patternTop: {
    position: 'absolute',
    top: 25,
    right: 25,
    width: 527,
    // height: 421,
    '@media(max-width: 980px)': {
      right: 0,
      bottom: 0,
      width: 139,
      // height: 233
    }
  },
  patternBottom: {
    position: 'absolute',
    left: 25,
    bottom: 25,
    width: 527,
    // height: 527,
    '@media(max-width: 980px)': {
      left: 0,
      bottom: 25,
      width: 139,
      // height: 233
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 112,
    '@media(max-width: 980px)': {
      paddingBottom: 60
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 50,
    '@media(max-width: 980px)': {
      flexDirection: 'column',
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '60%',
    // marginTop: 55,
    '@media(max-width: 980px)': {
      marginTop: 44,
      width: '100%',
    }
  },
  contentWrapperPlan: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    '@media(max-width: 980px)': {
      width: '100%',
    }
  },
  planRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  planColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  planHeading: {
    position: 'absolute',
    top: -12,
    backgroundColor: '#c5bdff',
    color: '#462eed',
    width: 100,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 14,
    borderRadius: 5,
    margin: '0 auto'
  },
  pro: {
    backgroundColor: '#462eed',
    color: '#ffffff'
  },
  wrap: {
    position: 'relative',
    marginRight: 20,
    padding: 16,
    border: 'solid 1px #000',
    borderRadius: 5
  },
  title: {
    margin: '10px 0',
    textAlign: 'center',
    fontSize: 20,
    color: '#102754',
    fontWight: bold
  },
  list: {
    paddingLeft: 10,
    listStyle: 'disc'
  },
  featureCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // marginRight: 101,
    marginLeft: 50,
    '@media(max-width: 980px)': {
      marginLeft: 0
    }
  },
  featureDetailsWrapperXl: {
    width: '50%',
    '@media(max-width: 980px)': {
      display: 'none'
    }
  },
  buttonWrapperXS: {
    display: 'none',
    '@media(max-width: 980px)': {
      display: 'flex',
      marginTop: 44,
      alignSelf: 'center'
    }
  },
  heading: {
    fontSize: 48,
    lineHeight: '45px',
    color: '#333333',
    fontWight: 'bold',
    '@media(max-width: 980px)': {
      fontSize: 26,
      lineHeight: '32px'
    }
  },
  subText: {
    fontSize: 28,
    lineHeight: '45px',
    color: '#333333',
    marginRight: 286,
    marginTop: 17,
    '@media(max-width: 980px)': {
      fontSize: 18,
      lineHeight: '24px',
      marginRight: 16,
      marginTop: 27
    }
  }
}

export default styles
