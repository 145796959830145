/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import TickMark from '../../Assets/svg/TickMark'

const TickBulletin = ({ text }) => {
  return (
    <article css={s.container}>
      <article css={s.tickView}>
        <article css={s.tick}>
        {/* ✓ */}
        <TickMark fill='#ffffff' width={8} />
        </article>
      </article>
      <article css={s.description}>{text}</article>
    </article>
  )
}

export default TickBulletin

/* Documentation Section */

TickBulletin.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

TickBulletin.defaultProps = {
  text: ''
}
