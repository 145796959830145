const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 113,
    '@media(max-width: 980px)': {
      marginTop: 58
    }
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '0 auto'
  },
  heading: {
    display: 'flex',
    flex: 1,
    fontSize: 46,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '42px',
    color: '#2d2d2d',
    '@media(max-width: 980px)': {
      fontSize: 26,
      lineHeight: '32px',
      color: '#333333'
    }
  },
  subText: {
    flex: 1,
    display: 'flex',
    fontSize: 18,
    alignSelf: 'center',
    color: '#2d2d2d',
    lineHeight: '24px',
    marginTop: 30,
    '@media(max-width: 980px)': {
      fontSize: 18,
      lineHeight: '24px',
      color: '#333333'
    }
  },
  listWrapper: {
    display: 'flex',
    marginTop: 78,
    flexDirection: 'column',
    alignSelf:"center",
    overflowX: 'scroll',
    '@media(max-width: 980px)': {
      width: '100%',
      marginTop: 55,
      flexDirection: 'row'
    }
  },
  bottomListItem: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    '@media(max-width: 980px)': {
      marginTop: 0,
    }
  },
  arrowWrap: {
    display: 'none',
    '@media(max-width: 980px)': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 14
    }
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: '0 20px',
    borderRadius: '50%',
    border: 'solid 1px #ddd',
    cursor: 'pointer',
    svg: {
      width: 18,
      height: 18
    }
  }
}

export default styles
