/** @jsxImportSource @emotion/react */
import React from 'react'
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
// import BarButton from '../../../Components/BarButton'
// import LiveDemoPopup from '../../../Components/LiveDemoPopup'
import AppStoreButton from '../../../Components/AppStoreButton'
import { mimoCover } from '../../../Lib/Images'

const ButtonView = ({
  onPress,
  text,
  visiblexs,
  visiblexl,
}) => {
  return (
    <React.Fragment>
      <article
        css={s.buttonView({ visiblexs: visiblexs, visiblexl: visiblexl })}
      >
        {/* <LiveDemoPopup /> */}
        <AppStoreButton />
        {/* <BarButton text={text} onPress={onPress} /> */}
        <article css={s.buttonDescription} onClick={onPress}>
          {' '}
          Why trust MIMO for your Home
          <br />
          Management Needs
        </article>
      </article>
    </React.Fragment>
  )
}
const BannerCard = ({ onWhy }) => {
  return (
    <article css={s.container}>
      <article css={s.wrapper}>
        <article css={s.title}>Easiest way to manage your rental home!</article>
        <article css={s.description}>
          {' '}
          Complete home management software trusted by over 100,000+ landlords
          and property managers!{' '}
        </article>
        <ButtonView
          text='Request live demo'
          visiblexl
          onPress={onWhy}
        />
      </article>
      <article css={s.imageWrap}>
        <img
          css={s.image}
          src={mimoCover}
        />
      </article>
      <ButtonView text='Request live demo' visiblexs />
    </article>
  )
}

export default BannerCard

/* Documentation Section */

BannerCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

BannerCard.defaultProps = {}
