import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import './_DatePicker.scss'
import { strftime, strptime } from '../../Lib/DateTime'
import { CalendarIcon } from '../../Lib/Images'

class DatePicker extends Component {
  render () {
    const {
      onChange,
      placeholder,
      disabledDays,
      selectedDay,
      value,
      month
    } = this.props
    return (
      <div className='DatePicker'>
        <DayPickerInput
          onDayChange={onChange}
          placeholder={placeholder}
          formatDate={strftime}
          format={'DD/MM/YYYY'}
          parseDate={strptime}
          value={value}
          dayPickerProps={{
            month,
            disabledDays: disabledDays,
            selectedDays: [selectedDay]
          }}
        />
        <div className='CalendarIcon'>
          <img src={CalendarIcon} />
        </div>
      </div>
    )
  }
}

export default DatePicker
