import React from 'react'
import './_PopUpStyle.scss'
import { blockPageScroll } from '../../Lib/css'
import { listenToKeyPress } from '../../Lib/listener'
import { PrimeButton } from '../Buttons'

class PopUp extends React.Component {
  componentDidMount () {
    blockPageScroll(true)
    this.props.getPopUpContent &&
      this.props.getPopUpContent(this.refs.PopUpContent)
    listenToKeyPress('add', this.checkKeys)
  }

  componentWillUnmount () {
    blockPageScroll(false)
    listenToKeyPress('remove', this.checkKeys)
  }

  hidePopUp = e => {
    if (this.props.cantClose) return
    if (this.props.onClose) {
      this.props.onClose(e)
    } else {
      window.goBack()
    }
  }

  closeButton = () =>
    this.props.hideCrossButton ? null : (
      <div className='close closeHover' onClick={this.hidePopUp}>
        ×
      </div>
    )

  checkKeys = keys => {
    if (keys === 'Escape') {
      this.hidePopUp()
    }
  }

  render () {
    const {
      className,
      small,
      normal,
      large,
      show = true,
      hideHeading,
      mainHeading,
      subHeading,
      children,
      topHeading,
      close = true,
      tag = false,
      hideClose,
      closeButtonText,
      onPopupContentClick
    } = this.props
    return show ? (
      <div className='PopUp' onClick={this.hidePopUp}>
        <div
          className={`PopUpContent${className ? ` ${className}` : ''} ${
            small ? ' smallPop' : ''
          } ${normal ? ' normalPop' : ''} ${large ? ' largePop' : ''} `}
          onClick={onPopupContentClick || (e => e.stopPropagation())}
        >
          {!hideHeading ? (
            <div className='header withContent'>
              {this.closeButton()}
              {topHeading && <div className='sub'>{topHeading}</div>}
              {mainHeading && (
                <div className='main'>
                  <strong>{mainHeading}</strong>
                  {tag && <span className='new-tag'>{tag}</span>}
                </div>
              )}
              {subHeading && <div className='sub'>{subHeading}</div>}
            </div>
          ) : (
            this.closeButton()
          )}
          <div className='content' ref='PopUpContent'>
            {children}
            {!hideClose && close && (
              <div className='visible-xs'>
                <PrimeButton
                  onClick={this.hidePopUp}
                  className='closeBtn center'
                  text={closeButtonText || 'CLOSE'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    ) : null
  }
}

export default PopUp
