const styles = {
  container: {
    // position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    // marginLeft: '10%',
    margin: '0 auto',
    paddingTop: 23,
    paddingBottom: 23,
    maxWidth: 1060,
    // alignItems: 'center',
    '@media(max-width: 980px)': {
      flexDirection: 'column',
      marginLeft: '25'
      // alignItemss: 'flexStart',
    }
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    '@media(max-width: 980px)': {
      width: '100%',
      paddingRight: 16,
      paddingLeft: 16
    }
  },
  heading: {
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: 35,
    lineHeight: '45px',
    '@media(max-width: 980px)': {
      fontSize: 24
    }
  },
  subText: {
    display: 'flex',
    fontSize: 18,
    lineHeight: '22px',
    color: '#3b3b3b',
    '@media(max-width: 980px)': {
      fontSize: 16
    }
  },
  // ourListWrapper: {
  //   position: 'relative',
  //   display: 'flex',
  //   width: '50%',
  //   '@media(max-width: 980px)': {
  //     width: '100%',
  //     marginTop: 28,
  //   }
  // },
  ourList: {
    display: 'flex',
    overflowX: 'scroll',
    display: 'flex',
    width: '50%',
    '@media(max-width: 980px)': {
      width: '100%',
      marginTop: 28
    }
  },
  icon: {
    height: 84,
    width: 84,
    marginRight: 24,
    borderRadius: 84 / 2
  },
  // arrow: {
  //   display: 'flex',
  //   height: 84,
  //   width: 84,
  //   marginRight: 24,
  //   borderRadius: 84 / 2,
  //   background: 'pink',
  //   alignSelf: 'center'
  // },
  // absIcon: {
  //   display: 'flex',
  //   position: 'absolute',
  //   top: 0,
  //   right: 0,
  //   bottom: 0,
  //   alignItems: 'center',
  //   justifyContent: 'center'
  // },
  imageItem: {
    display: 'flex'
  },
  arrowWrap: {
    display: 'none',
    '@media(max-width: 980px)': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10
    }
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: '0 20px',
    borderRadius: '50%',
    border: 'solid 1px #ddd',
    cursor: 'pointer',
    svg: {
      width: 18,
      height: 18
    }
  }
}

export default styles
