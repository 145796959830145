/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'

const TopSection = ({ title, description, profilePic, socialIcon }) => (
  <article css={s.topWrapper}>
    <img css={s.profileIcon} src={profilePic} />
    <article css={s.profileWrapper}>
      <article css={s.profileTextWrapper}>
        <article css={s.title}>{title}</article>
        <article css={s.description}>{description}</article>
      </article>
      <img css={s.socialIcon} src={socialIcon} />
    </article>
  </article>
)
const SocialMediaCard = ({
  message,
  alignTop,
  title,
  description,
  profilePic,
  socialIcon
}) => {
  return (
    <article css={s.container(alignTop)}>
      <article css={s.wrappper}>
        <TopSection
          title={title}
          description={description}
          profilePic={profilePic}
          socialIcon={socialIcon}
        />
        <article css={s.message}>{message}</article>
      </article>
    </article>
  )
}

export default SocialMediaCard

/* Documentation Section */

SocialMediaCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

SocialMediaCard.defaultProps = {
  message: '',
  title: '',
  description: '',
  profilePic: {},
  socialIcon: {}
}
