const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    display: 'flex',
    height: 132,
    width: 132
  },
  infoWrapper: {
    paddingLeft: 30
  },
  buttonWrapperXS: {
    display: 'flex',
    marginTop: 20,
    '@media(max-width: 980px)': {
      display: 'none'
    }
  },
  title: {
    display: 'flex',
    color: '#2d2d2d',
    fontSize: 18,
    fonWeight: 'bold'
  },
  desc: {
    display: 'flex',
    color: '#2d2d2d',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: 13
  }
}

export default styles
