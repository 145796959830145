const styles = {
  container: headerStyle => {
    if (!headerStyle) {
      return {
        flex: 1,
        background: '#462eed',
        textAlign: 'center',
        paddingLeft: 44,
        cursor: 'pointer',
        paddingRight: 44,
        paddingTop: 22,
        paddingBottom: 22,
        fontSize: 16,
        color: 'white'
      }
    }
    return {
      display: 'flex',
      padding: '5px 20px',
      borderRadius: 21,
      color: 'white',
      background: '#462eed',
      textAlign: 'center',
      fontSize: 16,
      cursor: 'pointer'
    }
  },
  buttonHeight: props => {
    if(props.headerStyle) return {}
    if (props?.small) {
      return {
        // maxHeight: 44,
        paddingTop: 11,
        paddingBottom: 11,
        fontSize: 16
      }
    }
    if (props?.large) {
      return {
        fontSize: 22,
        paddingTop: 15,
        paddingBottom: 15
        // maxHeight: 72
      }
    }
    return {
      maxHeight: 54,
      paddingTop: 14,
      paddingBottom: 14
    }
  }
}

export default styles
