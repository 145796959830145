const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  wrapper:{
    borderTop: 'solid 1px #dddddd',
    display: 'flex',
    padding: 20,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  header:{
  display:'flex',
  marginLeft :20
  },
  title: {
    marginTop: 34,
    display: 'flex',
    fontSize: 16,
    color: 'green',
    textAlign: 'center'
  },
  description: {
    marginTop: 20,
    display: 'flex',
    fontSize: 16,
    textAlign: 'center'
  },
  image: {
    width: 240
  }
}

export default styles
