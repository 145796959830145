const styles = {
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#dadada',
    background: 'white',
    borderRadius: 8,
    marginBottom: 10,
    '@media(max-width: 980px)': {
      fontSize: 18,
      paddingBottom: 10,
      // padding: '30px 12px 10px 16px',
      padding: '9px 17px 3px 25px'
    }
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  tag: {
    display: 'flex',
    padding: '2px 10px',
    borderRadius: 10,
    fontSize: 12,
    lineHeight: '16px',
    background: '#68ffc7',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#102754'
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 26,
    justifyContent: 'center',
    '@media(max-width: 980px)': {
      marginLeft: 14
    }
  },
  infoTitle: {
    display: 'flex',
    fontSize: 20,
    fontWight: 'bold',
    color: '#102754',
    '@media(max-width: 980px)': {
      fontSize: 16
    }
  },
  infoDesc: {
    display: 'flex',
    fontSize: 14,
    color: '#4a4a4a',
    '@media(max-width: 980px)': {
      fontSize: 14
    }
  },
  linkWrapper: {
    display: 'none',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '@media(max-width: 980px)': {
      display: 'flex'
    }
  },
  infoLink: {
    fontSize: 14,
    textDecoration: 'underline',
    lineHeight: '40px',
    color: '#22aff1',
    marginLeft: 15
  },
  infoIcon: {
    display: 'flex',
    width: 71,
    '@media(max-width: 980px)': {
      display: 'flex',
      width: 69,
      height: 'auto'
    }
  }
}

export default styles
