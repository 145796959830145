/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import {
  homesNetwork,
  pageViewNetwork,
  rentNetwork,
  tenantNetwork
} from '../../Lib/Images'
const DATA = [
  {
    text: '100K+',
    description: 'Tenants',
    icon: tenantNetwork
  },
  {
    text: '30K+',
    description: 'Active homes',
    icon: homesNetwork
  },
  {
    text: '$120M+',
    description: 'Annual rent processed',
    icon: rentNetwork
  },
  {
    text: '12M+',
    description: 'Pageviews/month',
    icon: pageViewNetwork
  }
]

const NetworkCard = props => {
  return (
    <article css={s.container}>
      <article css={s.itemContainer}>
        {DATA.map(({ text, description, icon }) => (
          <article css={s.itemView}>
            <article>
              <img css={s.icon} src={icon} />
            </article>
            <article css={s.infoWrapper}>
              <article css={s.title}>{text}</article>
              <article css={s.desc}>{description}</article>
            </article>
          </article>
        ))}
      </article>
    </article>
  )
}

export default NetworkCard

/* Documentation Section */

NetworkCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

NetworkCard.defaultProps = {}
