import React, { useState } from 'react'
import dayjs from 'dayjs'
// import { post } from '_utils/api'
import DatePicker from '../DatePicker'
import DropDown from '../DropDown'
import DotLoader from '../DotLoader'

import PopUp from '../PopUp'
import { PrimeButton } from '../Buttons'
import InputBox from '../InputBox'
import BarButton from '../BarButton'
import { TIME_ARRAY } from './data'
import './_styles.scss'

const LiveDemoPopup = ({ headerStyle }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [phone, setPhone] = useState('')
  const [leadId, setLeadId] = useState('')
  const [email, setEmail] = useState('')
  const [submitted, setSubmit] = useState(false)
  const [loader, setLoader] = useState(false)
  const [dateParams, setDateParams] = useState({ date: '', time_slot: '' })

  const [countryCode, setCountryCode] = useState('+1')

  const [error, setError] = useState('')

  const errorText = phone => {
    if (phone.length === 10) {
      setError('')
    } else {
      setError('Invalid phone number')
    }
  }

  const handleShowPopup = () => setShowPopup(!showPopup)
  const handlePhone = value => {
    setPhone(value)
    errorText(value)
  }

  const onCountryCodeChange = value => {
    setCountryCode(value.val)
    if (value.country === '') {
      setError('Invalid country code')
    } else setError('')
  }

  const emailAddress = email => {
    setEmail(email)
  }

  const handleSubmit = type => {
    const closePopup = type === 'closePopup'
    errorText(phone)
    if (error === '' && phone.length === 10) {
      const data = {
        phone_number: `${countryCode}${phone}`,
        created_at: new Date().toLocaleString('en-US'),
        iso_time: new Date().toISOString(),
        email: email,
        ...dateParams,
        ...window.getUrlParams()
      }
      setLoader(true)
      const leadUrl =
        submitted && leadId
          ? `https://production-nestaway.firebaseio.com/saas_leads/${leadId}.json`
          : `https://production-nestaway.firebaseio.com/saas_leads.json`

      fetch(leadUrl, {
        method: submitted && leadId ? 'PATCH': 'POST',
        body: JSON.stringify(data)
      })
        .then(r => r.json())
        .then(data => {
          setLoader(false)
          if (!data.error) {
            setSubmit(true)
            setLeadId(data.name)
          }
          if (closePopup) {
            setShowPopup(false)
          }
        })
        .catch(() => {
          setLoader(false)
        })
    }
  }

  const onDateChange = day => {
    const sanitizeDate = dayjs(day).format('DD/MM/YYYY')
    setDateParams({
      date: sanitizeDate,
      timeSlot: dateParams.timeSlot
    })
  }

  const onSelect = timeSlot => {
    setDateParams({
      date: dateParams.date,
      time_slot: timeSlot.value
    })
  }

  const dateFormate = () => {
    const date = TIME_ARRAY.map(item => {
      return {
        text: item.text,
        value: item.from
      }
    })

    return date
  }

  const DateSelectSection = () => {
    const today = new Date()
    today.setDate(today.getDate() + 1)
    const disabledDays = [{ daysOfWeek: [2] }, { before: today }]

    return (
      <div className='saasDateTimePicker'>
        <div className='dateSection'>
          <div className='label'>Select date</div>
          <DatePicker
            onChange={onDateChange}
            placeholder='YYYY-MM-DD'
            disabledDays={disabledDays}
            // value={this.props.keyCollectionDate}
          />
        </div>
        <div className='dateSection'>
          <div className='label'>Select time slot</div>
          <DropDown
            className='keyDropDown'
            options={dateFormate()}
            onChange={onSelect}
            // defaultValue={this.timeIndex() !== -1 ? this.timeIndex() : 0}
          />
        </div>
        {loader ? (
          <div className='dotLoaderWrap'>
            <DotLoader inlineBlock />
          </div>
        ) : (
          <PrimeButton
            className='submitSaasLiveDemo'
            onClick={() => handleSubmit('closePopup')}
            text='Submit'
            disabled={!dateParams.date || !dateParams.time_slot}
          />
        )}
      </div>
    )
  }

  return (
    <div className='liveDemoPopup'>
      <BarButton
        onClick={handleShowPopup}
        text='Get a Free Demo'
        headerStyle={headerStyle}
      />
      {/* <PrimeButton onClick={handleShowPopup} text='REQUEST LIVE DEMO' /> */}
      {showPopup ? (
        <PopUp
          mainHeading={submitted ? 'Let us know of a good time to chat.' : ''}
          small
          onClose={handleShowPopup}
          hideClose
        >
          {!submitted ? (
            <React.Fragment>
              <InputBox
                type='phone'
                placeholder='Phone number*'
                label='Phone number'
                selectCountryCodes
                country='United States'
                onChange={handlePhone}
                controlled
                value={phone}
                onCountryCodeChange={onCountryCodeChange}
                error={error}
                onEnter={handleSubmit}
              />
              <InputBox
                placeholder='Email Id *'
                label='Email Id *'
                type='text'
                controlled
                onChange={emailAddress}
                onEnter={handleSubmit}
              />
              {loader ? (
                <div className='dotLoaderWrap'>
                  <DotLoader inlineBlock />
                </div>
              ) : (
                <PrimeButton
                  className='submitSaasLiveDemo'
                  onClick={handleSubmit}
                  text='Continue'
                />
              )}
            </React.Fragment>
          ) : (
            DateSelectSection()
          )}
        </PopUp>
      ) : null}
    </div>
  )
}

export default LiveDemoPopup
