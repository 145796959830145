/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'

const FeatureInfoCard = ({
  image,
  tag,
  title,
  description,
  color,
  active,
  onPress,
  onPressLeftLink,
  onPressRightLink
}) => {

  return (
    <article
      css={[s.container, { borderColor: active ? '#22aff1' : '#dadada' }]}
      onClick={onPress}
    >
      <article css={s.topWrapper}>
        <article>
        <img css={s.infoIcon} src={image} />
        </article>
        <article css={s.infoWrapper}>
          {tag && (
            <article css={{ display: 'flex', flexShirink: 1 }}>
              <article css={s.tag}>free</article>
            </article>
          )}
          <article css={[s.infoTitle, color]}>{title}</article>
          <article css={s.infoDesc}>{description}</article>
        </article>
      </article>
      <article css={s.linkWrapper}>
        <article onClick={onPressLeftLink} css={s.infoLink}>
          Features
        </article>
        <article onClick={onPressRightLink} css={s.infoLink}>
          Pricing
        </article>
      </article>
    </article>
  )
}

export default FeatureInfoCard

/* Documentation Section */

FeatureInfoCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

FeatureInfoCard.defaultProps = {
  color: {},
  image: {},
  tag: '',
  title: '',
  description: '',
  active: false,
  onPressRightLink: () => {},
  onPressLeftLink: () => {}
}
