/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import s from './styles.js'
import FeatureInfoCard from '../FeatureInfoCard'
import FeatureDetails from '../FeatureDetails'
import PopUp from '../../../Components/PopUp'
import BarButton from '../../../Components/BarButton'
import LiveDemoPopup from '../../../Components/LiveDemoPopup'
import AppStoreButton from '../../../Components/AppStoreButton'

import {
  propertyManager,
  landLoard,
  patternBottom,
  patternTop
} from '../../../Lib/Images'

const BasicPlan = [
  'Syndicated Listings',
  'Credit and Background Screening',
  'Lease Management',
  'Online Rent Payments',
  'Tenant Portal',
  'Maintenance Tracking',
  'Tax Management',
  'Customer Support'
]
const ProPlan = [
  'Everything in the Basic Landlord Plan',
  'Dedicated Customer Support',
  'Customer Applications and Lease',
  'Access to Premium Leads',
  'Free Properties Website',
  'Waived ACH Fees'
]

const FeatureView = props => {
  const [currentView, setView] = useState('land_lord')
  const [selectedLink, setLink] = useState('')
  const [showPopUp, setShowPopUp] = useState(false)

  const onPressLink = (view, link) => () => {
    setView(view)
    setShowPopUp(true)
    setLink(link)
  }

  return (
    <article css={s.container}>
      <img css={s.patternTop} src={patternTop} />
      <img css={s.patternBottom} src={patternBottom} />
      <article css={s.wrapper}>
        <article css={s.heading}>Features & Pricing</article>
        <article css={s.subText}>
          Everything needed for property management. We have reasonable & honest
          pricing.
        </article>
        <article css={s.contentContainer}>
          <article css={s.contentWrapperPlan}>
            <article css={s.planRow}>
              <article css={s.planColumn}>
                <div css={s.wrap}>
                  <div css={s.planHeading}>Basic</div>
                  <div css={s.title}>0$/month</div>
                  <ul css={s.list}>
                    {BasicPlan.map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </article>
              <article css={s.planColumn}>
                <div css={s.wrap}>
                  <div css={[s.planHeading, s.pro]}>Pro</div>
                  <div css={s.title}>3$/month</div>
                  <ul css={s.list}>
                    {ProPlan.map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </article>
            </article>
          </article>
          <article css={s.contentWrapper}>
            <article css={s.featureCardWrapper}>
              <FeatureInfoCard
                onPressLeftLink={onPressLink('land_lord', 'left_link')}
                onPressRightLink={onPressLink('land_lord', 'right_link')}
                active={currentView === 'land_lord'}
                onPress={() => setView('land_lord')}
                image={landLoard}
                tag
                title='Free For Landlords'
                description='Best for upto 10 properties (for individuals)'
              />
              <FeatureInfoCard
                onPressLeftLink={onPressLink('property_manager', 'left_link')}
                onPressRightLink={onPressLink('property_manager', 'right_link')}
                onPress={() => setView('property_manager')}
                active={currentView !== 'land_lord'}
                image={propertyManager}
                color={{ color: '#22aff1' }}
                title='For Property Managers'
                description='Best for 10+ properties (for teams)'
              />
              <article css={s.buttonWrapperXS}>
                <AppStoreButton />
                {/* <LiveDemoPopup text='Request live demo' /> */}
              </article>
            </article>
            <article css={s.featureDetailsWrapperXl}>
              <FeatureDetails currentView={currentView} />
            </article>
            {showPopUp && (
              <PopUp onClose={() => setShowPopUp(false)} hideClose>
                <FeatureDetails
                  currentView={currentView}
                  selectedLink={selectedLink}
                />
              </PopUp>
            )}
          </article>
        </article>
      </article>
    </article>
  )
}

export default FeatureView

/* Documentation Section */

FeatureView.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

FeatureView.defaultProps = {}
