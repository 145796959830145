/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
// import BarButton from '../BarButton'
// import LiveDemoPopup from '../LiveDemoPopup'
import AppStoreButton from '../../Components/AppStoreButton'

const InfoCard = ({ image, title, description, onPress, buttonText }) => {
  return (
    <article css={s.container}>
      <img css={s.image} src={image} />
      <article css={s.infoWrapper}>
        {!onPress && <article css={s.title}>{title}</article>}
        <article css={s.desc}>{description}</article>
        {onPress && (
          <article css={s.buttonWrapperXS}>
            <AppStoreButton />
            {/* <LiveDemoPopup /> */}
            {/* <BarButton text={buttonText} /> */}
          </article>
        )}
      </article>
    </article>
  )
}

export default InfoCard

/* Documentation Section */

InfoCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

InfoCard.defaultProps = {
  title: '',
  description: '',
  image: {},
  onPress: () => {}
}
