/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import SimpleVideoPlayer from '../../Components/SimpleVideoPlayer'

const ImageInfoCardLarge = ({ image, text, highlitedText, info, video }) => {
  return (
    <article css={s.container}>
      <article css={s.imageWrapper}>
        {/* <article css={s.image}> */}
          <SimpleVideoPlayer
            autoPlay
            image={image}
            tumpnail={{}}
            style={s.image}
            src={video}
          />
      </article>
      <article css={s.infoWrapper}>
        <article css={s.infoText}>{text}</article>
        <article css={s.bottomInfoWrapper}>
          <article css={s.infoBold}>{highlitedText}</article>
          <article css={s.infoBottom}>{info}</article>
        </article>
      </article>
    </article>
  )
}

export default ImageInfoCardLarge

/* Documentation Section */

ImageInfoCardLarge.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

ImageInfoCardLarge.defaultProps = {
  image: {}
}
