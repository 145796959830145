const styles = {
  container: {
    flex: 1
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems:'center'
    '@media(max-width: 980px)': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  itemView: {
    display: 'flex',
    padding: 6,
    width: '25%',
    '@media(max-width: 980px)': {
      width: '100%',
    }
  },
  title: {
    fontSize: 18,
    color: '#1c326e',
    fontWeignt: 'bold',
    '@media(max-width: 980px)': {
      fontSize: 14
    }
  },
  desc: {
    fontSize: 14,
    color: '#1c326e',
    lineHeight:'24px',
    '@media(max-width: 980px)': {
      fontSize: 14
    }
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15
  },
  icon: {
    // height: 'auto',
    width: 44
  }
}

export default styles
