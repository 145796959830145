const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-Start',
    minHeight: 44,
    marginBOtoom: 10
  },
  tickView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 16,
    width: 16,
    minHeight: 16,
    minWidth:16,
    background:'#22aff1',
    borderRadius: 8
  },
  tick: {
    display: 'flex',
    fontSize: 10,
    lineHeight: '18px',
    color: '#102754'
  },
  description: {
    display: 'flex',
    marginLeft: 12,
    fontSize: 14,
    lineHeight: '18px',
    color: '#989898'
  }
}

export default styles
