/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import Arrow from '../../../Assets/svg/Arrow'

import s from './styles.js'
import ImageInfoCardLarge from '../../../Components/ImageInfoCardLarge'
import PressCard from '../../../Components/PressCard'
import {
  liveMint,
  techCrunch,
  theHindu,
  vccircle,
  economicTimes,
  storyCover
} from '../../../Lib/Images'
import { windowScroll } from '../../../Lib/css'

const DATA = [
  {
    text: `NestAway, which is perhaps the most funded player in this space, was founded by Sahu, Dhar, Smruti Parida and Jitendra Jagadev in January 2015. It turns 'for-rent' apartments into managed, fully-furnished houses and provides them to pre-verified tenants.`,
    linkText: 'View the entire article',
    user: 'Vijayakumar Pitchiah @Vccircle',
    link:
      'https://www.vccircle.com/tiger-global-backed-home-rental-startup-nestaway-bags-50-mn-from-ratan-tata/',
    image: vccircle
  },
  {
    text: `In a press statement, Tiger Global partner Lee Fixel said, "NestAway's customised strategy has demonstrated the potential to transform India's rental housing market by leveraging a long-term view of the owner-tenant relationship. By emphasising the highest quality of customer service and satisfaction, NestAway is developing an annuity-based e-commerce model at scale.`,
    linkText: 'View the entire article',
    user: 'Catherine Shu, @Techcrunch',
    link: 'https://techcrunch.com/2016/04/19/nestaway/',
    image: techCrunch
  },
  {
    text: `Currently, Nestaway which is backed by Tiger Global, IDG Ventures, Ratan Tata, Yuri Milner and Goldman Sachs has more than 50,000 tenants and 60,000 homes in its network. The company acts as a property management firm for house owners by connecting them with prospective tenants and providing services from renting to re-renting, creating the rent agreement, renewing it, handling property damages, legal disputes etc.`,
    linkText: 'View the entire article',
    user: 'Sangeetha Chengappa, @The Hindu Businessline',
    link:
      'https://www.thehindubusinessline.com/companies/covid-effect-nestaway-launches-franchise-model-to-tap-growth-beyond-metros/article32569209.ece',
    image: theHindu
  },
  {
    text: `Yet, as Sahu and his partners tackle what they know is a big social challenge whose success depends entirely on a large group of people doing the right thing, they remain optimistic. "Five years from now you'll see a very different India. We are betting our careers on that.`,
    linkText: 'View the entire article',
    user: 'Priya Ramani, @Livemint',
    link:
      'https://www.livemint.com/Leisure/nBk1zBMgciPTVmSjTltM7O/Opinion--A-home-away-from-prejudice.html',
    image: liveMint
  },
  {
    text: `But NestAway isn't just a discovery platform to find properties. It provides several other services: acting as a property manager and using design and technology to lower costs and ease all payments and transactions from fees to billing. "We aren't merely transactional. We add value to the entire process from start to finish," explains Sahu, with the main one being trust. So,`,
    linkText: 'View the entire article',
    user: 'Jyoti Pande Lavakare, @Economic Times',
    link:
      'https://economictimes.indiatimes.com/small-biz/startups/how-young-startup-nestaway-is-creating-marketplace-for-shared-home-rentals/articleshow/49611167.cms',
    image: economicTimes
  }
]

const message =
  '“Nestaway works towards solving the existing trust-deficit between homeowners and tenants, thereby developing a social infrastructure for affordable renting. We started in 2015 with 5 employees, 4 homes in a small office in Bangalore. Now Nestaway has grown to be the largest managed home-rental market in the country with 60,000 tenants who call us their homes in 35,000+ homes and 800+ employees across 13 cities.'

const OurStory = props => {
  const houseInterest = useRef(null)
  const onNav = direction => () => {
    if (direction === 'left') {
      windowScroll(
        houseInterest.current,
        houseInterest.current.scrollLeft - 500,
        300,
        'horizontal'
      )
    } else {
      windowScroll(
        houseInterest.current,
        houseInterest.current.scrollLeft + 500,
        300,
        'horizontal'
      )
    }
  }
  return (
    <article css={s.container} ref={props.forwardRef}>
      <article css={s.wrapper}>
        <article css={s.heading}>Our Story</article>
        <article css={s.subText}>
        Started in 2015, we're India's largest Managed Home Rental Platform
        </article>
        <article css={s.cardWrapper}>
          <ImageInfoCardLarge
            image={storyCover}
            video={'https://nestaway-assets.akamaized.net/assets/app/video_what_is_nestaway.mp4'}
            text={message}
            highlitedText='-Amarendra Sahu,'
            info='CEO & Co-founder of Nestaway'
          />
        </article>
      </article>
      <article css={s.pressList} ref={houseInterest}>
        {DATA.map(({ text, linkText, user, link, image }) => (
          <article css={s.presItemWrapper}>
            <PressCard
              text={text}
              linkText={linkText}
              user={user}
              link={link}
              image={image}
            />
          </article>
        ))}
      </article>
      <article css={s.arrowWrap}>
        <article css={s.arrow} onClick={onNav('left')}>
          <Arrow rotate={'left'} color='#999' />
        </article>
        <article css={s.arrow} onClick={onNav('right')}>
          <Arrow rotate={'right'} color='#999' />
        </article>
      </article>
    </article>
  )
}

export default OurStory

/* Documentation Section */

OurStory.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

OurStory.defaultProps = {}
