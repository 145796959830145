const styles = {
  container: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#f4f4f4',
    background: '#ffffff',
    // padding: 36,
    paddingLeft: 35,
    '@media(max-width: 980px)': {
      padding: '38px 10px 38px 10px',
    
    }
  },
  bottomWrapper: {
    display: 'flex',
    paddingTop: 26,
    paddingBottom: 30,
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 9,
    boderColor: '#102754',
    marginTop: 35
  },
  bottomView: highlite => {
    return {
      display: 'flex',
      justifyContent: 'center',
      fontsize: 17.5,
      padding: '11px 30px 11px 30px',
      background: highlite ? '#87ffd2' : ''
    }
  },
  bottomTitle: {
    display: 'flex',
    fontsize: 17.5,
    color: '#102754',
    fontWeight: 600
  },
  description: {
    display: 'flex',
    fontsize: 17.5,
    color: '#102754',
    padding: '17px 30px 7px 30px'
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 32,
    justifyContent: 'center'
  }
}

export default styles
