const classNames = (defaultClasses = '', classConditions) => {
  // a simple implementation of npm classNames
  // defaultClasses : a string of class or classes separated by space
  // classConditions : its should be object with boolean data.
  // It boolean is true it will accept the key.
  // If string is sent instead of boolean the string will be accepted as the className
  // usage: className={classNames('someDefaulClassName', {show: isFlag, additionalClass: this.props.className})}
  defaultClasses = defaultClasses.split(' ')
  Object.keys(classConditions).map(key => {
    const condition = classConditions[key]
    if (typeof condition === 'string') {
      return defaultClasses.push(condition)
    }
    if (condition) {
      defaultClasses.push(key)
    }
  })
  return defaultClasses.join(' ')
}

const blockPageScroll = block => {
  document.body.style.overflowY = block ? 'hidden' : ''
}



Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

let windowScrollTimer = null

const windowScroll = (
  element = window,
  to,
  duration = 500,
  direction = 'vertical'
) => {
  // pass wrapper element as first param and scroll(animated) horizontal/vertical. pass window object itself to scroll the body
  let isElement = true
  if (element.document) {
    isElement = false
  }
  if (element) {
    const start =
      direction === 'vertical'
        ? isElement
          ? element.scrollTop
          : element.scrollY
        : element.scrollLeft
    const change = to - start
    let currentTime = 0
    const increment = 20
    clearTimeout(windowScrollTimer)
    const animateScroll = function () {
      currentTime += increment
      const val = Math.easeInOutQuad(currentTime, start, change, duration)
      if (direction === 'vertical') {
        element.scrollTo(0, val)
      } else {
        element.scrollTo(val, 0)
      }
      if (currentTime < duration) {
        windowScrollTimer = setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  }
}


export {classNames, blockPageScroll, windowScroll}