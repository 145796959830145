/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import SocialMediaCard from '../../../Components/SocialMediaCard'
import { useRef } from 'react'
import Arrow from '../../../Assets/svg/Arrow'
import { windowScroll } from '../../../Lib/css'
import { REVIEW_DATA } from './data'

const SocialMediaAboutUs = props => {
  const topData = REVIEW_DATA.slice(0, 4)
  const bottomData = REVIEW_DATA.slice(4, 8)
  const socialRef = useRef(null)
  const onNav = direction => () => {
    if (direction === 'left') {
      windowScroll(
        socialRef.current,
        socialRef.current.scrollLeft - 300,
        300,
        'horizontal'
      )
    } else {
      windowScroll(
        socialRef.current,
        socialRef.current.scrollLeft + 300,
        300,
        'horizontal'
      )
    }
  }
  return (
    <article css={s.container} ref={props.forwardRef}>
      <article css={s.wrapper}>
        <article css={s.heading}>Don’t take our word for it?</article>
        <article css={s.subText}>See what our customers are saying</article>
      </article>
      <article css={s.listWrapper} ref={socialRef}>
        <article css={{ display: 'flex', flexDirection: 'row' }}>
          {topData.map(
            ({ title, description, profilePic, socialIcon, message }) => (
              <SocialMediaCard
                message={message}
                title={title}
                description={description}
                profilePic={profilePic}
                socialIcon={socialIcon}
              />
            )
          )}
        </article>
        <article css={s.bottomListItem}>
          {bottomData.map(
            ({ title, description, profilePic, socialIcon, message }) => (
              <SocialMediaCard
                alignTop
                message={message}
                title={title}
                description={description}
                profilePic={profilePic}
                socialIcon={socialIcon}
              />
            )
          )}
        </article>
      </article>
      <article css={s.arrowWrap}>
        <article css={s.arrow} onClick={onNav('left')}>
          <Arrow rotate={'left'} color='#999' />
        </article>
        <article css={s.arrow} onClick={onNav('right')}>
          <Arrow rotate={'right'} color='#999' />
        </article>
      </article>
    </article>
  )
}

export default SocialMediaAboutUs

/* Documentation Section */

SocialMediaAboutUs.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

SocialMediaAboutUs.defaultProps = {}
