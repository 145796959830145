import React from 'react'
import './_Button.scss'
// import { trackingEvent } from '../../components/pages/Common/Tracking/api'

class Button extends React.PureComponent {
  typeClassName = {
    flat: 'FlatButton',
    normal: 'NormalButton',
    prime: 'PrimeButton'
  }

  onClick = e => {
    if (this.props.disabled) {
      this.props.onDisabledClick && this.props.onDisabledClick(e)
      return
    }

    const trackingObject = {}
    if (this.props.gaLabel) trackingObject.label = this.props.gaLabel
    if (this.props.gaAction) trackingObject.action = this.props.gaAction
    if (this.props.gaCategory) {
      trackingObject.category = this.props.gaCategory
      // trackingEvent(trackingObject)
    }

    this.props.onClick && this.props.onClick(e)
  }

  generateClasses = () => {
    const { active, disabled, round, className, type } = this.props

    return [
      'Button',
      this.typeClassName[type],
      active ? 'active' : '',
      disabled ? 'disabled' : '',
      round ? 'round' : '',
      className
    ]
      .filter(item => item && item.length > 0)
      .join(' ')
  }

  render () {
    const { text, DropDown } = this.props

    return (
      <div
        id={this.props.id}
        className={this.generateClasses()}
        onClick={this.onClick}
      >
        {text} {DropDown && DropDown()}
        {this.props.subText && (
          <div className='subText'>{this.props.subText}</div>
        )}
      </div>
    )
  }
}

export default Button
