/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import s from './styles.js'

const SimpleVideoPlayer = props => {
  const [play, setPlay] = useState(false)
  const vidRef = useRef(null)

  const onClickPlay = () => {
    if (play) {
      setPlay(!play)
      return vidRef?.current?.pause()
    }
    setPlay(!play)
    vidRef?.current?.play()
  }

  const PlayButton = () => (
    <article onClick={onClickPlay} css={s.playButtonContaine}>
      {!play && <article css={s.playButton} />}
    </article>
  )

  return (
    <article css={{ display: 'flex', position: 'relative', background: 'yellow', flex:1 }}>
      <video
        ref={vidRef}
        css={props.style}
        controls
        src={props.src}
        poster={props.image}
        onEnded={() => setPlay(false)}
      />
      {/* <PlayButton onClick={onClickPlay} /> */}
    </article>
  )
}

export default SimpleVideoPlayer

/* Documentation Section */

SimpleVideoPlayer.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

SimpleVideoPlayer.defaultProps = {
  poster:{},
  src:{}
}
