/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import { headerLogo, ownerBannerImg } from '../../Lib/Images'
const HeaderTab = ({ data, scrollToItem, style }) => {
  const onClick = (id, url) => () => {
    if (!url) {
      return scrollToItem(id)
    }
    window.open(url)
  }

  return (
    <article css={[s.container, style ]}>
      <img css={s.logo} src={headerLogo} />
      <article css={s.contentView}>
        {data.map(item => {
          if (item.getItem) {
            return <article css={{ paddingLeft: 44 }}>{item.getItem()}</article>
          }
          return (
            <article
              css={[s.itemText, item.hilight ? s.itemHiglited : {}]}
              onClick={onClick(item.id, item.url)}
            >
              {item.text}
            </article>
          )
        })}
      </article>
    </article>
  )
}

export default HeaderTab

/* Documentation Section */

HeaderTab.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

HeaderTab.defaultProps = {
  data: []
}
