/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'

const BarButton = props => {
  return (
    <article css={[s.container(props.headerStyle), s.buttonHeight(props)]} onClick={props.onClick}>
      {props?.text}
    </article>
  )
}

export default BarButton

/* Documentation Section */

BarButton.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

BarButton.defaultProps = {
  onClick: () => {}
}
