const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    '@media(max-width: 980px)': {
      flexDirection: 'column',
      background: '#d4e8ff',
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#102754',
      // padding: '28px 13px 43px 15px'
      // padding: '28px 6px 43px 6px'
      padding:20

    }
  },
  infoText: {
    display: 'flex',
    fontSize: 26,
    fontWeight:'bold',
    lineHeight: '40px',
    '@media(max-width: 980px)': {
      // fontSize: 24,
      fontSize: 20,
      textAlign: 'center',
      lineHeight: '32px',
      color: '#434343'
    }
  },
  buttonWrapper: {
    display: 'flex',
    margin: '0 auto',
    '@media(max-width: 980px)': {
      marginTop: 41
    }
  }
}

export default styles
