const styles = {
  container: {
    display: 'flex',
    flex: 1,
    '@media(max-width: 980px)': {
      display: 'flex',
      marginLeft: 28,
      marginRight: 28,
      flexDirection: 'column'
    }
  },
  wrapper: {
    // width: '65%',
    // width: '30%',
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 980px)': {
      display: 'flex',
      width: '100%'
    }
  },
  imageWrap: {
    // width: '35%',
    display: 'flex',
    width: 371,
    flexDirection: 'column',
    '@media(max-width: 980px)': {
      display: 'flex',
      width: '100%'
    }
  },
  title: {
    display: 'flex',
    color: '#282828',
    fontSize: '56px',
    lineHeight: '72px',
    marginBottom: '25px',
    fontWeight: 'bold',
    '@media(max-width: 980px)': {
      display: 'flex',
      fontSize: '26px',
      lineHeight: '32px',
      marginBottom: '29px'
    }
  },
  description: {
    display: 'flex',
    color: '#000000',
    fontSize: '18px',
    lineHeight: '24px',
    marginBottom: '59px',
    marginRight: '10px'
  },
  image: {
    display: 'flex',
    width: 371,
    '@media(max-width: 980px)': {
      display: 'flex',
      width: 226
      // height: 228
    }
  },
  buttonDescription: {
    display: 'flex',
    color: '#22aff1',
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 'bold',
    lineHeight: '14px',
    paddingRight: 4,
    marginTop: 15
  },
  buttonView: ({ visiblexl, visiblexs }) => {
    return {
      display: visiblexs ? 'none' : 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start',
      '@media(max-width: 980px)': {
        display: visiblexl ? 'none' : 'flex',
        marginTop: 55,
        marginBottom: 15
      }
    }
  }
}

export default styles
