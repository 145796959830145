const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '@media(max-width: 980px)': {
      alignItems: 'center'
    }
  },
  headerView: {
    display: 'flex',
    flex: 1,
    borderBottom: 'solid 1px #dddddd',
    display: 'flex',
    paddingBottom: 10,
    '@media(max-width: 980px)': {
      display: 'flex',
      paddingLeft: 28,
      width: '100%' // mobile horizontal scroll problem?
    }
  },
  separator: {
    display: 'flex',
    height: 1,
    width: '100%',
    background: 'grey'
  },
  benefitWrapper: {
    display: 'flex',
    background: '#d4e8ff',
    paddingTop: 59 + 20,
    marginTop: 59 + 78,
    '@media(max-width: 980px)': {
      paddingTop: 337 / 2 + 20,
      marginTop: 337 / 2 + 63
    }
  },
  investorsWrapper: {
    background: '#d4e8ff',
  },
  bannerContainer: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: 1060,
    marginTop: 100,
    '@media(max-width: 980px)': {
      marginTop: 59
    }
  },
  demoCardWrapper: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: 1060,
    marginTop: 100,
    '@media(max-width: 980px)': {
      marginTop: 59,
      padding: 16
    }
  },
  footerMargin: {
    display: 'flex',
    height: 70,
    '@media(max-width: 980px)': {
      height: 59
    }
  }
}

export default styles
