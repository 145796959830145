const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '23px 26px 26px 30px',
    borderStyle: 'solid',
    borderWidth: 0.5,
    minWidth: 636,
    borderRadius:5,
    borderColor: '#dddddd',
    background: '#ffffff',
    '@media(max-width: 980px)': {
      minWidth: 307,
      marginLeft: 6,
      marginRight: 6
    }
    // padding: '18px 18px 15px 14px'
  },
  infoWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 31,
    // marginLeft: 80,
    marginLeft: 70,
    // marginRight: 50,
    marginRight: 20,
    '@media(max-width: 980px)': {
      flexDirection: 'column',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0
    }
  },
  bottomWrapper: {
    display: 'flex',
    marginTop: 46,
    justifyContent: 'sapace-between',
    '@media(max-width: 980px)': {
      flexDirection: 'column',
      marginTop: 14
    }
  },
  bottomLeft: {
    display: 'flex',
    flex: 1,
    color: '#2e418a'
  },
  bottomRight: {
    display: 'flex',
    color: '#4bb8f6',
    '@media(max-width: 980px)': {
      marginTop: 14
    }
  },
  pressIcon: {
    display: 'flex',
    height: 28,
    width: 112,
    resizeMode: 'contain'
  },
  quates: {
    fontSize: 64,
    color: '#152c61',
    lineHeight: '64px',
    marginRight: 16
  },
  infoText: {
    display: 'flex',
    flexDirection: 'column',
    flex:1,
    // fontSize: 18,
    // lineHeight: '22px',
    fontSize: 16,
    lineHeight: '18px',
    color: '#1c326e'
  }
}

export default styles
