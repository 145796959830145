export const TIME_ARRAY = [
    {
      from: '10:00:00',
      to: '11:00:00',
      text: 'Select time slot',
      available: true,
      note: ''
    },
    {
      from: '10:00:00',
      to: '11:00:00',
      text: '10:00 AM - 11:00 AM',
      available: true,
      note: ''
    },
    {
      from: '11:00:00',
      to: '12:00:00',
      text: '11:00 AM - 12:00 PM',
      available: true,
      note: ''
    },
    {
      from: '12:00:00',
      to: '13:00:00',
      text: '12:00 PM - 01:00 PM',
      available: true,
      note: ''
    },
    {
      from: '13:00:00',
      to: '14:00:00',
      text: '01:00 PM - 02:00 PM',
      available: true,
      note: ''
    },
    {
      from: '14:00:00',
      to: '15:00:00',
      text: '02:00 PM - 03:00 PM',
      available: true,
      note: ''
    },
    {
      from: '15:00:00',
      to: '16:00:00',
      text: '03:00 PM - 04:00 PM',
      available: true,
      note: ''
    },
    {
      from: '16:00:00',
      to: '17:00:00',
      text: '04:00 PM - 05:00 PM',
      available: true,
      note: ''
    },
    {
      from: '17:00:00',
      to: '18:00:00',
      text: '05:00 PM - 06:00 PM',
      available: true,
      note: ''
    },
    {
      from: '18:00:00',
      to: '19:00:00',
      text: '06:00 PM - 07:00 PM',
      available: true,
      note: ''
    },
    {
      from: '19:00:00',
      to: '20:00:00',
      text: '07:00 PM - 08:00 PM',
      available: true,
      note: ''
    }
  ]