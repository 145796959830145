import { playstore } from '../../../Lib/Images'
const REVIEW_DATA = [
  {
    title: 'Matthew Kam',
    description: '',
    message: `I have a hectic job and have 3 rental homes. I needed a mobile-friendly software which helps me have a good handle on things anytime, anywhere and a friend told me about MIMO. I started using the product 3 months ago and it has been a smooth experience for me with MIMO so far.`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'sunitha khariwal',
    description: '',
    message: `This app is best for house owners. I listed my property with Nestaway and got amazing tenants in just 1 month. Thanks Sweety for informing us about Nestaway. It has helped us a lot.`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Ritesh Malik',
    description: '',
    message: `I have two rental homes and it was very difficult for me and my wife to keep track of things, until MIMO came along. MIMO has helped us get comfort on the safety of our property and I am able to view all rent statements in one place and manage my tenants. Thank you MIMO!`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Sujeeth Ravi',
    description: '',
    message: `Liked the Service of Mr.John (APM Velachery) initially didn't like the service of Nestaway but once John came as my APM the experience was totally different!! He helped me in everything!! I had to make an internal transfer and that was done in the most smoothest way possible.. all kudos to John.. keep doing great!`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Anil Kumar',
    description: '',
    message: `I had a great time dealing with Nestaway. The customer support was very quick. And thanks Shilpa shree for reacting quickly to my queries and also for making the refund process very quick.`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Neel chokshi',
    description: '',
    message: `Services of Nestaway have been improved a lot in last couple of months, mainly due to good and resposive area managers like Shivam. Giving 5* only because of Shivam s continuous support and his commitment for providing better services.`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Shrenik Tambe',
    description: '',
    message: `One of the best premium properties you can ever get! Have been staying in Nestaway properties since last 3 years now! Special mention about my relationship manager Mr. Dileep Srivastava - He has been always helpful and supportive and has been always proactively resolving all the issues. One of the best customer service you can ever get. Would definitely recommend Nestaway to everyone.`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  },
  {
    title: 'Ritesh Malik',
    description: '',
    message: `I have two rental homes and it was very difficult for me and my wife to keep track of things, until MIMO came along. MIMO has helped us get comfort on the safety of our property and I am able to view all rent statements in one place and manage my tenants. Thank you MIMO!`,
    profilePic:
      'https://storage.pixteller.com/designs/designs-images/2016-11-19/02/img_page_1_58305b35ebf5e.png',
    socialIcon: playstore
  }
]
export { REVIEW_DATA }
