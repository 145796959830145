import React from 'react'
// import font from './Assets/fonts/GraphikRegular.otf'
import font from './Assets/fonts/Montserrat-Medium.woff'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// import LandingView from './Containers/LandingView'
import StripeSuccessView from './Containers/StripeSuccessView'
import LandingView from './Containers/NewLandingView'
import logo from './logo.svg'
import './App.css'

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const Page = () => <div>Hi Firends</div>
const App = () => (
  <Router>
    <style type='text/css'>
      {`@font-face {
            font-family: Montserrat-Medium;
            font-display: auto;
            src:url(${font})
          }`}
    </style>
    <Switch>
      <Route path={'/stripe_success'} component={StripeSuccessView} />
      <Route path={'/'} component={LandingView} />
    </Switch>
  </Router>
)

export default App
