const styles = {
  container: {
    display: 'flex',
    maxWidth: '100%',
    '@media(max-width: 980px)': {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%'
    }
  },
  imageWrapper: {
    display: 'flex',
    width: '60%',
    '@media(max-width: 980px)': {
      display:'inline-block',
      width: '100%'
    }
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    padding: 50,
    background: '#102754',
    '@media(max-width: 980px)': {
      width: '100%',
      padding: 15
    }
  },
  infoText: {
    display: 'flex',
    color: '#ffffff',
    fontSize: 16,
    lineHeight: '28px'
  },
  image: {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: '#102754'
  },
  bottomInfoWrapper:{
    display: 'flex',
    flexDirection: 'column'
  },
  infoBottom: {
    display: 'flex',
    color: '#22aff1',
    fontSize: 16,
    lineHeight: '28px',
  },
  infoBold: {
    display: 'flex',
    color: '#22aff1',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '28px',
    marginTop: 25
  }
}

export default styles
