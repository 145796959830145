/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { useRef } from 'react'
import PropTypes from 'prop-types'
import s from './styles.js'
import Arrow from '../../Assets/svg/Arrow'
import { saasTiger, saasSachs, saasIdg, saasDst } from '../../Lib/Images'
import { windowScroll } from '../../Lib/css'

const INVESTORS = [
  {
    img: saasTiger,
    link: ''
  },
  {
    img: saasSachs,
    link: ''
  },
  {
    img: saasIdg,
    link: ''
  },
  {
    img: saasDst,
    link: ''
  }
]

const OurInvestors = props => {
  const investorRef = useRef(null)
  // const scrollTo = () => {
  //   investorRef.current.scrollLeft += 84
  // }
  const onNav = direction => () => {
    if (direction === 'left') {
      windowScroll(
        investorRef.current,
        investorRef.current.scrollLeft - 500,
        300,
        'horizontal'
      )
    } else {
      windowScroll(
        investorRef.current,
        investorRef.current.scrollLeft + 500,
        300,
        'horizontal'
      )
    }
  }
  return (
    <article css={s.container}>
      <article css={s.titleWrapper}>
        <article css={s.heading}>Our Investors</article>
        <article css={s.subText}>
          backing us to build the complete home rental software stack
        </article>
      </article>
      {/* <article css={s.ourListWrapper}> */}
      <article css={s.ourList} ref={investorRef}>
        {INVESTORS.map(item => (
          <article css={s.imageItem}>
            <img css={s.icon} src={item.img} />
          </article>
        ))}
      </article>
      <article css={s.arrowWrap}>
        <article css={s.arrow} onClick={onNav('left')}>
          <Arrow rotate={'left'} color='#999' />
        </article>
        <article css={s.arrow} onClick={onNav('right')}>
          <Arrow rotate={'right'} color='#999' />
        </article>
      </article>
      {/* <article css={s.absIcon}>
          <article css={s.arrow} onClick={scrollTo}>
            <Arrow rotate={'right'} color='#000' />
          </article>
        </article> */}
      {/* </article> */}
    </article>
  )
}

export default OurInvestors

/* Documentation Section */

OurInvestors.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

OurInvestors.defaultProps = {}
