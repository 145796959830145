export default [
  {
    text: 'India',
    shortText: '+91',
    code: 'IN'
  },
  {
    text: 'Afghanistan',
    shortText: '+93',
    code: 'AF'
  },
  {
    text: 'Albania',
    shortText: '+355',
    code: 'AL'
  },
  {
    text: 'Algeria',
    shortText: '+213',
    code: 'DZ'
  },
  {
    text: 'AmericanSamoa',
    shortText: '+1 684',
    code: 'AS'
  },
  {
    text: 'Andorra',
    shortText: '+376',
    code: 'AD'
  },
  {
    text: 'Angola',
    shortText: '+244',
    code: 'AO'
  },
  {
    text: 'Anguilla',
    shortText: '+1 264',
    code: 'AI'
  },
  {
    text: 'Antigua and Barbuda',
    shortText: '+1268',
    code: 'AG'
  },
  {
    text: 'Argentina',
    shortText: '+54',
    code: 'AR'
  },
  {
    text: 'Armenia',
    shortText: '+374',
    code: 'AM'
  },
  {
    text: 'Aruba',
    shortText: '+297',
    code: 'AW'
  },
  {
    text: 'Australia',
    shortText: '+61',
    code: 'AU'
  },
  {
    text: 'Austria',
    shortText: '+43',
    code: 'AT'
  },
  {
    text: 'Azerbaijan',
    shortText: '+994',
    code: 'AZ'
  },
  {
    text: 'Bahamas',
    shortText: '+1 242',
    code: 'BS'
  },
  {
    text: 'Bahrain',
    shortText: '+973',
    code: 'BH'
  },
  {
    text: 'Bangladesh',
    shortText: '+880',
    code: 'BD'
  },
  {
    text: 'Barbados',
    shortText: '+1 246',
    code: 'BB'
  },
  {
    text: 'Belarus',
    shortText: '+375',
    code: 'BY'
  },
  {
    text: 'Belgium',
    shortText: '+32',
    code: 'BE'
  },
  {
    text: 'Belize',
    shortText: '+501',
    code: 'BZ'
  },
  {
    text: 'Benin',
    shortText: '+229',
    code: 'BJ'
  },
  {
    text: 'Bermuda',
    shortText: '+1 441',
    code: 'BM'
  },
  {
    text: 'Bhutan',
    shortText: '+975',
    code: 'BT'
  },
  {
    text: 'Bosnia and Herzegovina',
    shortText: '+387',
    code: 'BA'
  },
  {
    text: 'Botswana',
    shortText: '+267',
    code: 'BW'
  },
  {
    text: 'Brazil',
    shortText: '+55',
    code: 'BR'
  },
  {
    text: 'British Indian Ocean Territory',
    shortText: '+246',
    code: 'IO'
  },
  {
    text: 'Bulgaria',
    shortText: '+359',
    code: 'BG'
  },
  {
    text: 'Burkina Faso',
    shortText: '+226',
    code: 'BF'
  },
  {
    text: 'Burundi',
    shortText: '+257',
    code: 'BI'
  },
  {
    text: 'Cambodia',
    shortText: '+855',
    code: 'KH'
  },
  {
    text: 'Cameroon',
    shortText: '+237',
    code: 'CM'
  },
  {
    text: 'Canada',
    shortText: '+1',
    code: 'CA'
  },
  {
    text: 'Cape Verde',
    shortText: '+238',
    code: 'CV'
  },
  {
    text: 'Cayman Islands',
    shortText: '+ 345',
    code: 'KY'
  },
  {
    text: 'Central African Republic',
    shortText: '+236',
    code: 'CF'
  },
  {
    text: 'Chad',
    shortText: '+235',
    code: 'TD'
  },
  {
    text: 'Chile',
    shortText: '+56',
    code: 'CL'
  },
  {
    text: 'China',
    shortText: '+86',
    code: 'CN'
  },
  {
    text: 'Christmas Island',
    shortText: '+61',
    code: 'CX'
  },
  {
    text: 'Colombia',
    shortText: '+57',
    code: 'CO'
  },
  {
    text: 'Comoros',
    shortText: '+269',
    code: 'KM'
  },
  {
    text: 'Congo',
    shortText: '+242',
    code: 'CG'
  },
  {
    text: 'Cook Islands',
    shortText: '+682',
    code: 'CK'
  },
  {
    text: 'Costa Rica',
    shortText: '+506',
    code: 'CR'
  },
  {
    text: 'Croatia',
    shortText: '+385',
    code: 'HR'
  },
  {
    text: 'Cuba',
    shortText: '+53',
    code: 'CU'
  },
  {
    text: 'Cyprus',
    shortText: '+537',
    code: 'CY'
  },
  {
    text: 'Czech Republic',
    shortText: '+420',
    code: 'CZ'
  },
  {
    text: 'Denmark',
    shortText: '+45',
    code: 'DK'
  },
  {
    text: 'Djibouti',
    shortText: '+253',
    code: 'DJ'
  },
  {
    text: 'Dominica',
    shortText: '+1 767',
    code: 'DM'
  },
  {
    text: 'Dominican Republic',
    shortText: '+1 849',
    code: 'DO'
  },
  {
    text: 'Ecuador',
    shortText: '+593',
    code: 'EC'
  },
  {
    text: 'Egypt',
    shortText: '+20',
    code: 'EG'
  },
  {
    text: 'El Salvador',
    shortText: '+503',
    code: 'SV'
  },
  {
    text: 'Equatorial Guinea',
    shortText: '+240',
    code: 'GQ'
  },
  {
    text: 'Eritrea',
    shortText: '+291',
    code: 'ER'
  },
  {
    text: 'Estonia',
    shortText: '+372',
    code: 'EE'
  },
  {
    text: 'Ethiopia',
    shortText: '+251',
    code: 'ET'
  },
  {
    text: 'Faroe Islands',
    shortText: '+298',
    code: 'FO'
  },
  {
    text: 'Fiji',
    shortText: '+679',
    code: 'FJ'
  },
  {
    text: 'Finland',
    shortText: '+358',
    code: 'FI'
  },
  {
    text: 'France',
    shortText: '+33',
    code: 'FR'
  },
  {
    text: 'French Guiana',
    shortText: '+594',
    code: 'GF'
  },
  {
    text: 'French Polynesia',
    shortText: '+689',
    code: 'PF'
  },
  {
    text: 'Gabon',
    shortText: '+241',
    code: 'GA'
  },
  {
    text: 'Gambia',
    shortText: '+220',
    code: 'GM'
  },
  {
    text: 'Georgia',
    shortText: '+995',
    code: 'GE'
  },
  {
    text: 'Germany',
    shortText: '+49',
    code: 'DE'
  },
  {
    text: 'Ghana',
    shortText: '+233',
    code: 'GH'
  },
  {
    text: 'Gibraltar',
    shortText: '+350',
    code: 'GI'
  },
  {
    text: 'Greece',
    shortText: '+30',
    code: 'GR'
  },
  {
    text: 'Greenland',
    shortText: '+299',
    code: 'GL'
  },
  {
    text: 'Grenada',
    shortText: '+1 473',
    code: 'GD'
  },
  {
    text: 'Guadeloupe',
    shortText: '+590',
    code: 'GP'
  },
  {
    text: 'Guam',
    shortText: '+1 671',
    code: 'GU'
  },
  {
    text: 'Guatemala',
    shortText: '+502',
    code: 'GT'
  },
  {
    text: 'Guinea',
    shortText: '+224',
    code: 'GN'
  },
  {
    text: 'Guinea-Bissau',
    shortText: '+245',
    code: 'GW'
  },
  {
    text: 'Guyana',
    shortText: '+595',
    code: 'GY'
  },
  {
    text: 'Haiti',
    shortText: '+509',
    code: 'HT'
  },
  {
    text: 'Honduras',
    shortText: '+504',
    code: 'HN'
  },
  {
    text: 'Hungary',
    shortText: '+36',
    code: 'HU'
  },
  {
    text: 'Iceland',
    shortText: '+354',
    code: 'IS'
  },
  {
    text: 'India',
    shortText: '+91',
    code: 'IN'
  },
  {
    text: 'Indonesia',
    shortText: '+62',
    code: 'ID'
  },
  {
    text: 'Iraq',
    shortText: '+964',
    code: 'IQ'
  },
  {
    text: 'Ireland',
    shortText: '+353',
    code: 'IE'
  },
  {
    text: 'Israel',
    shortText: '+972',
    code: 'IL'
  },
  {
    text: 'Italy',
    shortText: '+39',
    code: 'IT'
  },
  {
    text: 'Jamaica',
    shortText: '+1 876',
    code: 'JM'
  },
  {
    text: 'Japan',
    shortText: '+81',
    code: 'JP'
  },
  {
    text: 'Jordan',
    shortText: '+962',
    code: 'JO'
  },
  {
    text: 'Kazakhstan',
    shortText: '+7 7',
    code: 'KZ'
  },
  {
    text: 'Kenya',
    shortText: '+254',
    code: 'KE'
  },
  {
    text: 'Kiribati',
    shortText: '+686',
    code: 'KI'
  },
  {
    text: 'Kuwait',
    shortText: '+965',
    code: 'KW'
  },
  {
    text: 'Kyrgyzstan',
    shortText: '+996',
    code: 'KG'
  },
  {
    text: 'Latvia',
    shortText: '+371',
    code: 'LV'
  },
  {
    text: 'Lebanon',
    shortText: '+961',
    code: 'LB'
  },
  {
    text: 'Lesotho',
    shortText: '+266',
    code: 'LS'
  },
  {
    text: 'Liberia',
    shortText: '+231',
    code: 'LR'
  },
  {
    text: 'Liechtenstein',
    shortText: '+423',
    code: 'LI'
  },
  {
    text: 'Lithuania',
    shortText: '+370',
    code: 'LT'
  },
  {
    text: 'Luxembourg',
    shortText: '+352',
    code: 'LU'
  },
  {
    text: 'Madagascar',
    shortText: '+261',
    code: 'MG'
  },
  {
    text: 'Malawi',
    shortText: '+265',
    code: 'MW'
  },
  {
    text: 'Malaysia',
    shortText: '+60',
    code: 'MY'
  },
  {
    text: 'Maldives',
    shortText: '+960',
    code: 'MV'
  },
  {
    text: 'Mali',
    shortText: '+223',
    code: 'ML'
  },
  {
    text: 'Malta',
    shortText: '+356',
    code: 'MT'
  },
  {
    text: 'Marshall Islands',
    shortText: '+692',
    code: 'MH'
  },
  {
    text: 'Martinique',
    shortText: '+596',
    code: 'MQ'
  },
  {
    text: 'Mauritania',
    shortText: '+222',
    code: 'MR'
  },
  {
    text: 'Mauritius',
    shortText: '+230',
    code: 'MU'
  },
  {
    text: 'Mayotte',
    shortText: '+262',
    code: 'YT'
  },
  {
    text: 'Mexico',
    shortText: '+52',
    code: 'MX'
  },
  {
    text: 'Monaco',
    shortText: '+377',
    code: 'MC'
  },
  {
    text: 'Mongolia',
    shortText: '+976',
    code: 'MN'
  },
  {
    text: 'Montenegro',
    shortText: '+382',
    code: 'ME'
  },
  {
    text: 'Montserrat',
    shortText: '+1664',
    code: 'MS'
  },
  {
    text: 'Morocco',
    shortText: '+212',
    code: 'MA'
  },
  {
    text: 'Myanmar',
    shortText: '+95',
    code: 'MM'
  },
  {
    text: 'Namibia',
    shortText: '+264',
    code: 'NA'
  },
  {
    text: 'Nauru',
    shortText: '+674',
    code: 'NR'
  },
  {
    text: 'Nepal',
    shortText: '+977',
    code: 'NP'
  },
  {
    text: 'Netherlands',
    shortText: '+31',
    code: 'NL'
  },
  {
    text: 'Netherlands Antilles',
    shortText: '+599',
    code: 'AN'
  },
  {
    text: 'New Caledonia',
    shortText: '+687',
    code: 'NC'
  },
  {
    text: 'New Zealand',
    shortText: '+64',
    code: 'NZ'
  },
  {
    text: 'Nicaragua',
    shortText: '+505',
    code: 'NI'
  },
  {
    text: 'Niger',
    shortText: '+227',
    code: 'NE'
  },
  {
    text: 'Nigeria',
    shortText: '+234',
    code: 'NG'
  },
  {
    text: 'Niue',
    shortText: '+683',
    code: 'NU'
  },
  {
    text: 'Norfolk Island',
    shortText: '+672',
    code: 'NF'
  },
  {
    text: 'Northern Mariana Islands',
    shortText: '+1 670',
    code: 'MP'
  },
  {
    text: 'Norway',
    shortText: '+47',
    code: 'NO'
  },
  {
    text: 'Oman',
    shortText: '+968',
    code: 'OM'
  },
  {
    text: 'Pakistan',
    shortText: '+92',
    code: 'PK'
  },
  {
    text: 'Palau',
    shortText: '+680',
    code: 'PW'
  },
  {
    text: 'Panama',
    shortText: '+507',
    code: 'PA'
  },
  {
    text: 'Papua New Guinea',
    shortText: '+675',
    code: 'PG'
  },
  {
    text: 'Paraguay',
    shortText: '+595',
    code: 'PY'
  },
  {
    text: 'Peru',
    shortText: '+51',
    code: 'PE'
  },
  {
    text: 'Philippines',
    shortText: '+63',
    code: 'PH'
  },
  {
    text: 'Poland',
    shortText: '+48',
    code: 'PL'
  },
  {
    text: 'Portugal',
    shortText: '+351',
    code: 'PT'
  },
  {
    text: 'Puerto Rico',
    shortText: '+1 939',
    code: 'PR'
  },
  {
    text: 'Qatar',
    shortText: '+974',
    code: 'QA'
  },
  {
    text: 'Romania',
    shortText: '+40',
    code: 'RO'
  },
  {
    text: 'Rwanda',
    shortText: '+250',
    code: 'RW'
  },
  {
    text: 'Samoa',
    shortText: '+685',
    code: 'WS'
  },
  {
    text: 'San Marino',
    shortText: '+378',
    code: 'SM'
  },
  {
    text: 'Saudi Arabia',
    shortText: '+966',
    code: 'SA'
  },
  {
    text: 'Senegal',
    shortText: '+221',
    code: 'SN'
  },
  {
    text: 'Serbia',
    shortText: '+381',
    code: 'RS'
  },
  {
    text: 'Seychelles',
    shortText: '+248',
    code: 'SC'
  },
  {
    text: 'Sierra Leone',
    shortText: '+232',
    code: 'SL'
  },
  {
    text: 'Singapore',
    shortText: '+65',
    code: 'SG'
  },
  {
    text: 'Slovakia',
    shortText: '+421',
    code: 'SK'
  },
  {
    text: 'Slovenia',
    shortText: '+386',
    code: 'SI'
  },
  {
    text: 'Solomon Islands',
    shortText: '+677',
    code: 'SB'
  },
  {
    text: 'South Africa',
    shortText: '+27',
    code: 'ZA'
  },
  {
    text: 'South Georgia and the South Sandwich Islands',
    shortText: '+500',
    code: 'GS'
  },
  {
    text: 'Spain',
    shortText: '+34',
    code: 'ES'
  },
  {
    text: 'Sri Lanka',
    shortText: '+94',
    code: 'LK'
  },
  {
    text: 'Sudan',
    shortText: '+249',
    code: 'SD'
  },
  {
    text: 'Suriname',
    shortText: '+597',
    code: 'SR'
  },
  {
    text: 'Swaziland',
    shortText: '+268',
    code: 'SZ'
  },
  {
    text: 'Sweden',
    shortText: '+46',
    code: 'SE'
  },
  {
    text: 'Switzerland',
    shortText: '+41',
    code: 'CH'
  },
  {
    text: 'Tajikistan',
    shortText: '+992',
    code: 'TJ'
  },
  {
    text: 'Thailand',
    shortText: '+66',
    code: 'TH'
  },
  {
    text: 'Togo',
    shortText: '+228',
    code: 'TG'
  },
  {
    text: 'Tokelau',
    shortText: '+690',
    code: 'TK'
  },
  {
    text: 'Tonga',
    shortText: '+676',
    code: 'TO'
  },
  {
    text: 'Trinidad and Tobago',
    shortText: '+1 868',
    code: 'TT'
  },
  {
    text: 'Tunisia',
    shortText: '+216',
    code: 'TN'
  },
  {
    text: 'Turkey',
    shortText: '+90',
    code: 'TR'
  },
  {
    text: 'Turkmenistan',
    shortText: '+993',
    code: 'TM'
  },
  {
    text: 'Turks and Caicos Islands',
    shortText: '+1 649',
    code: 'TC'
  },
  {
    text: 'Tuvalu',
    shortText: '+688',
    code: 'TV'
  },
  {
    text: 'Uganda',
    shortText: '+256',
    code: 'UG'
  },
  {
    text: 'Ukraine',
    shortText: '+380',
    code: 'UA'
  },
  {
    text: 'United Arab Emirates',
    shortText: '+971',
    code: 'AE'
  },
  {
    text: 'United Kingdom',
    shortText: '+44',
    code: 'GB'
  },
  {
    text: 'United States',
    shortText: '+1',
    code: 'US'
  },
  {
    text: 'Uruguay',
    shortText: '+598',
    code: 'UY'
  },
  {
    text: 'Uzbekistan',
    shortText: '+998',
    code: 'UZ'
  },
  {
    text: 'Vanuatu',
    shortText: '+678',
    code: 'VU'
  },
  {
    text: 'Wallis and Futuna',
    shortText: '+681',
    code: 'WF'
  },
  {
    text: 'Yemen',
    shortText: '+967',
    code: 'YE'
  },
  {
    text: 'Zambia',
    shortText: '+260',
    code: 'ZM'
  },
  {
    text: 'Zimbabwe',
    shortText: '+263',
    code: 'ZW'
  },
  {
    text: 'land Islands',
    shortText: '',
    code: 'AX'
  },
  {
    text: 'Antarctica',
    shortText: null,
    code: 'AQ'
  },
  {
    text: 'Bolivia, Plurinational State of',
    shortText: '+591',
    code: 'BO'
  },
  {
    text: 'Brunei Darussalam',
    shortText: '+673',
    code: 'BN'
  },
  {
    text: 'Cocos (Keeling) Islands',
    shortText: '+61',
    code: 'CC'
  },
  {
    text: 'Congo, The Democratic Republic of the',
    shortText: '+243',
    code: 'CD'
  },
  {
    text: "Cote d'Ivoire",
    shortText: '+225',
    code: 'CI'
  },
  {
    text: 'Falkland Islands (Malvinas)',
    shortText: '+500',
    code: 'FK'
  },
  {
    text: 'Guernsey',
    shortText: '+44',
    code: 'GG'
  },
  {
    text: 'Holy See (Vatican City State)',
    shortText: '+379',
    code: 'VA'
  },
  {
    text: 'Hong Kong',
    shortText: '+852',
    code: 'HK'
  },
  {
    text: 'Iran, Islamic Republic of',
    shortText: '+98',
    code: 'IR'
  },
  {
    text: 'Isle of Man',
    shortText: '+44',
    code: 'IM'
  },
  {
    text: 'Jersey',
    shortText: '+44',
    code: 'JE'
  },
  {
    text: "Korea, Democratic People's Republic of",
    shortText: '+850',
    code: 'KP'
  },
  {
    text: 'Korea, Republic of',
    shortText: '+82',
    code: 'KR'
  },
  {
    text: "Lao People's Democratic Republic",
    shortText: '+856',
    code: 'LA'
  },
  {
    text: 'Libyan Arab Jamahiriya',
    shortText: '+218',
    code: 'LY'
  },
  {
    text: 'Macao',
    shortText: '+853',
    code: 'MO'
  },
  {
    text: 'Macedonia, The Former Yugoslav Republic of',
    shortText: '+389',
    code: 'MK'
  },
  {
    text: 'Micronesia, Federated States of',
    shortText: '+691',
    code: 'FM'
  },
  {
    text: 'Moldova, Republic of',
    shortText: '+373',
    code: 'MD'
  },
  {
    text: 'Mozambique',
    shortText: '+258',
    code: 'MZ'
  },
  {
    text: 'Palestinian Territory, Occupied',
    shortText: '+970',
    code: 'PS'
  },
  {
    text: 'Pitcairn',
    shortText: '+872',
    code: 'PN'
  },
  {
    text: 'Réunion',
    shortText: '+262',
    code: 'RE'
  },
  {
    text: 'Russia',
    shortText: '+7',
    code: 'RU'
  },
  {
    text: 'Saint Barthélemy',
    shortText: '+590',
    code: 'BL'
  },
  {
    text: 'Saint Helena, Ascension and Tristan Da Cunha',
    shortText: '+290',
    code: 'SH'
  },
  {
    text: 'Saint Kitts and Nevis',
    shortText: '+1 869',
    code: 'KN'
  },
  {
    text: 'Saint Lucia',
    shortText: '+1 758',
    code: 'LC'
  },
  {
    text: 'Saint Martin',
    shortText: '+590',
    code: 'MF'
  },
  {
    text: 'Saint Pierre and Miquelon',
    shortText: '+508',
    code: 'PM'
  },
  {
    text: 'Saint Vincent and the Grenadines',
    shortText: '+1 784',
    code: 'VC'
  },
  {
    text: 'Sao Tome and Principe',
    shortText: '+239',
    code: 'ST'
  },
  {
    text: 'Somalia',
    shortText: '+252',
    code: 'SO'
  },
  {
    text: 'Svalbard and Jan Mayen',
    shortText: '+47',
    code: 'SJ'
  },
  {
    text: 'Syrian Arab Republic',
    shortText: '+963',
    code: 'SY'
  },
  {
    text: 'Taiwan, Province of China',
    shortText: '+886',
    code: 'TW'
  },
  {
    text: 'Tanzania, United Republic of',
    shortText: '+255',
    code: 'TZ'
  },
  {
    text: 'Timor-Leste',
    shortText: '+670',
    code: 'TL'
  },
  {
    text: 'Venezuela, Bolivarian Republic of',
    shortText: '+58',
    code: 'VE'
  },
  {
    text: 'Viet Nam',
    shortText: '+84',
    code: 'VN'
  },
  {
    text: 'Virgin Islands, British',
    shortText: '+1 284',
    code: 'VG'
  },
  {
    text: 'Virgin Islands, U.S.',
    shortText: '+1 340',
    code: 'VI'
  }
]
