const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: 118,
    paddingBottom: 89,
    background: '#f1f1f1'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    maxWidth: 1060,
    '@media(max-width: 980px)': {
      paddingRight: 26,
      paddingLeft: 26,
      // marginLeft: 26,
      // marginRight: 26,
    }
  },
  cardWrapper: {
    display: 'flex',
    marginTop: 39,
    '@media(max-width: 980px)': {
      display: 'flex',
      marginTop: 39
    }
  },
  heading: {
    display: 'flex',
    fontSize: 42,
    lineHeight: '45px',
    fontWeight: 'bold',
    color: '#2d2d2d',
    '@media(max-width: 980px)': {
      fontSize: 26,
      lineHeight: '32px',
      color: '#333333'
    }
  },
  subText: {
    display: 'flex',
    fontSize: 24,
    color: '#434343',
    marginTop: 26,
    lineHeight: '35px',
    '@media(max-width: 980px)': {
      fontSize: 18,
      color: '#333333'
    }
  },
  pressList: {
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    marginTop: 90,
    '@media(max-width: 980px)': {
    marginTop: 33
    }
  },
  presItemWrapper:{
    display: 'flex',
    marginLeft: 8,
    marginRight: 8,
    '@media(max-width: 980px)': {
      width: '100%',
    }
  },
  arrowWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,    
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    margin: '0 20px',
    borderRadius: '50%',
    border: 'solid 1px #ddd',
    cursor: 'pointer',
    svg: {
      width: 18,
      height: 18
    }
  }
}

export default styles
