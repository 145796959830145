/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import { Appstorebadge } from '../../Lib/Images'
// import s from './styles.js'

const AppStoreButton = props => {
  return (
    <a
      // css={s.container}
      href='https://apps.apple.com/us/app/mimo-by-nestaway/id1568658846'
      target='_blank'
    >
      <img src={Appstorebadge} alt={Appstorebadge} width={180} />
    </a>
  )
}

export default AppStoreButton

/* Documentation Section */

AppStoreButton.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

AppStoreButton.defaultProps = {}
