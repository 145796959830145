const styles = {
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    // borderStyle: 'solid',
    // borderTopWidth: 0,
    // borderLeftWidth:0,
    // borderRightWidth: 0, 
    // borderBottomWidth: 1, 
    // borderColor: 'grey' ,
    paddingTop: 8,
    paddingBottom: 8,
    maxWidth: 1060,
    margin: '0 auto'
    // '@media(max-width: 980px)': {
    //     display: 'none'
    //   }
  },
  contentView: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media(max-width: 980px)': {
        display: 'none'
      }

  },
  itemText: {
    fontSize: 16,
    color: '#282828',
    marginLeft: 44,
    cursor: 'pointer'
  },
  itemHiglited: {
    display: 'flex',
    background: '#102754',
    padding: '5px 20px',
    borderRadius: 21,
    color: 'white'
  },
  cardHeight: props => {
    if (props.small)
      return {
        height: 100
      }

    return {
      height: 200
    }
  },
  logo: {
    display: 'flex',
    width: 146,
    height: 58,
    '@media(max-width: 980px)': {
    display: 'flex',
    width: 68,
    height: 25,
      }
  }
}

export default styles
