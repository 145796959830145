const styles = {
  container: {
    flex: 1,
    position: 'relative',
    maxWidth: 1060,
    margin: '0 auto',
    '@media(max-width: 980px)': {
      marginLeft: 28,
      marginRight: 18
    }
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 70,
    paddingBottom: 70,
  },
  networkWrapper: {
    position: 'absolute',
    top: -118 - 20,
    left: 0,
    right: 0,
    justifyContent: 'center',
    '@media(max-width: 980px)': {
      top: -278 - 20 -30,
      width: '90%',
      margin: '0 auto'
    }
  },
  networkView: {
    justifyContent: 'center',
    padding: 30,
    margin: '0 auto',
    background: '#d4e8ff',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#9b9b9b',
    borderRadius: 5,
    '@media(max-width: 980px)': {
      padding: 24,
    }
  },
  itemWrapper: {
    display: 'flex',
    width: '45%',
    marginright: '5%',
    marginBottom: '5%',
    '@media(max-width: 980px)': {
      width: '90%',
      marginBottom: '5%',
      marginright: '0%',
      marginTop: 15
    }
  },
  buttnonWrapperXS: {
    display:'none',
    '@media(max-width: 980px)': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 93,
      marginTop: 56
    }
  }
}

export default styles
