/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import InfoCard from '../../../Components/InfoCard'
import NetworkCard from '../../../Components/NetworkCard'
// import BarButton from '../../../Components/BarButton'
// import LiveDemoPopup from '../../../Components/LiveDemoPopup'
import AppStoreButton from '../../../Components/AppStoreButton'

const BenefitsList = ({ data }) => {
  return (
    <article css={s.container}>
      <article css={s.networkWrapper}>
        <article css={s.networkView}>
          <NetworkCard />
        </article>
      </article>
      <article css={s.wrapper}>
        {data.map(({ image, title, description }, idx) => {
          return (
            <article css={s.itemWrapper}>
              <InfoCard
                image={image}
                title={title}
                description={description}
                onPress={data.length - 1 === idx ? () => {} : ''}
                buttonText='Request live demo'
              />
            </article>
          )
        })}
      </article>
      <article css={s.buttnonWrapperXS}>
        <AppStoreButton />
        {/* <LiveDemoPopup /> */}
        {/* <BarButton text='Request live demo' /> */}
      </article>
    </article>
  )
}

export default BenefitsList

/* Documentation Section */

BenefitsList.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

BenefitsList.defaultProps = {
  data: []
}
