/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import BarButton from '../BarButton'
import LiveDemoPopup from '../LiveDemoPopup'

const ActionCard = props => {
  return (
    <article css={s.container}>
      <arcticle  css={s.infoText}>Want to know more about property management?</arcticle>
      <article css={s.buttonWrapper}>
      {/* <BarButton text='Request live demo' /> */}
      <LiveDemoPopup />
      </article>
    </article>
  )
}

export default ActionCard

/* Documentation Section */

ActionCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

ActionCard.defaultProps = {}
