/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'

const PressCard = ({ link, text, linkText, user, image }) => {
  return (
    <article css={s.container}>
      <img css={s.pressIcon} src={image} />
      <article css={s.infoWrapper}>
        <article css={s.quates}>“</article>
        <article css={s.infoText}>
         {text}
        </article>
      </article>
      <article css={s.bottomWrapper}>
        <article css={s.bottomLeft}>{user}</article>
        <a href={link} css={s.bottomRight}>{linkText}</a>
      </article>
    </article>
  )
}

export default PressCard

/* Documentation Section */

PressCard.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

PressCard.defaultProps = {
  link: '',
  text: '',
  llinkText: '',
  user: '',
  image:{}
}
