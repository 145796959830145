/** @jsxImportSource @emotion/react */
import React from 'react'
import { useRef } from 'react'
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import HeaderTab from '../../Components/HeaderTab'
import Footer from '../../Components/Footer'
import OurInvestors from '../../Components/OurInvestors'
import ActionCard from '../../Components/ActionCard'
import BannerCard from './BannerCard'
import BenefitsList from './BenefitsList'
import FeatureView from './FeatureView'
import SocialMediaAboutUs from './SocialMediaAboutUs'
import OurStory from './OurStory'
import { BENEFITS_DATA, HEADER_DATA } from './data.js'

import s from './styles.js'

const NewLandingView = props => {
  const ourDreamRef = useRef(null)
  const featureRef = useRef(null)
  const testimonialsRef = useRef(null)
  const ourStoryRef = useRef(null)
  const bannerRef = useRef(null)

  const refs = {
    our_story: ourStoryRef,
    testimonials: testimonialsRef,
    Features_and_pricing: featureRef,
    our_dream: ourDreamRef,
    banner: bannerRef
  }

  const scrolltoSections = id => {
    refs[id]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  return (
    <React.Fragment>
      <article css={s.headerView}>
        <HeaderTab data={HEADER_DATA} scrollToItem={scrolltoSections} />
      </article>
      <article css={s.bannerContainer} ref={bannerRef}>
        <BannerCard onWhy={() => scrolltoSections('our_dream')} />
      </article>
      <article css={s.benefitWrapper} ref={ourDreamRef}>
        <BenefitsList data={BENEFITS_DATA} />
      </article>
      <article css={{ position: 'relative' }} ref={featureRef}>
        <FeatureView />
      </article>
      <OurStory forwardRef={ourStoryRef} />
      <article css={s.investorsWrapper}>
        <OurInvestors />
      </article>
      <SocialMediaAboutUs forwardRef={testimonialsRef} />
      <article css={s.demoCardWrapper}>
        <ActionCard />
      </article>
      <article css={s.footerMargin} />
      <Footer />
    </React.Fragment>
  )
}

export default NewLandingView

/* Documentation Section */

NewLandingView.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

NewLandingView.defaultProps = {}
