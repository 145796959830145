import React from 'react'
import './_DropDown.scss'
import Arrow from '../../Assets/svg/Arrow'
import PopUp from '../PopUp'

class DropDown extends React.Component {
  state = {
    selected: this.props.defaultValue || 0,
    showOptions: this.props.showOptions || false
  }

  showDropOptions = () => {
    this.refs.dropDown && this.refs.dropDown.focus()
    this.setState({
      showOptions: !this.state.showOptions
    })
  }

  selectOption = (option, index) => () => {
    this.setState({ selected: index })
    this.props.onChange && this.props.onChange(option, index)
    this.props.modal && this.onCloseModal()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        selected: nextProps.defaultValue
      })
    }
    if (nextProps.selected !== this.props.selected) {
      this.setState({
        selected: nextProps.selected
      })
    }
  }

  onDropBlur = () => {
    this.setState({
      showOptions: false
    })
  }

  DropDownOption = ({ maxHeight, selected }) => (
    <ul
      className='DropdownValue customScroll'
      style={maxHeight ? { maxHeight } : null}
    >
      {this.props.minimal && this.state.showOptions ? (
        <label>{this.props.label}</label>
      ) : null}
      {this.state.showOptions &&
        this.props.options.map((option, index) => (
          <li
            key={index}
            onClick={this.selectOption(option, index)}
            className={selected === index ? 'active' : ''}
          >
            {option.text}
          </li>
        ))}
    </ul>
  )

  onCloseModal = () => {
    this.setState({
      showOptions: false
    })
  }

  render () {
    const selected = this.state.selected
    const selectedOption = this.props.options[selected]
    const { maxHeight, arrowColor } = this.props
    return (
      <div
        id={this.props.id}
        className={`DropDown${this.props.growTop ? ' growTop' : ''} ${
          this.props.className
        }`}
        onClick={this.showDropOptions}
        ref='dropDown'
        tabIndex={-1}
        onBlur={this.onDropBlur}
      >
        {!this.props.minimal && !this.props.hideSpace ? (
          <label>{this.props.label}</label>
        ) : null}
        {this.props.icon && this.props.icon()}
        <div
          className={`activeState ${this.state.showOptions ? 'active' : ''}`}
        >
          <div className={'dropdown-text'}>
            {selectedOption && (selectedOption.shortText || selectedOption.text)
              ? selectedOption.shortText || selectedOption.text
              : this.props.defaultValue}{' '}
          </div>
          {!this.props.minimal ? (
            <Arrow
              rotate={this.state.showOptions ? 'up' : 'down'}
              color={arrowColor}
            />
          ) : null}
          {this.props.modal && this.state.showOptions ? (
            <PopUp onClose={this.onCloseModal} hideClose>
              <this.DropDownOption maxHeight={maxHeight} selected={selected} />
            </PopUp>
          ) : (
            <this.DropDownOption maxHeight={maxHeight} selected={selected} />
          )}
        </div>
      </div>
    )
  }
}

export default DropDown
