import React from 'react'
import './_InputBox.scss'
import CountryCodes from './countryCodes'
import { classNames } from '../../Lib/css'

const debounceDelay = 100
class InputBox extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      selectCountryCodes: false,
      ...this.props
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (
      nextProps.controlled &&
      (nextProps.value === '' ||
        (nextProps.value && prevState.value !== nextProps.value))
    ) {
      return {
        value: nextProps.value
      }
    } else {
      return {
        value: prevState.value
      }
    }
  }

  focus = type => {
    if (type === 'dates') {
      this.setState({ type: 'date' })
    }
    this.onFocus()
  }

  componentDidMount () {
    const { countryCode } = this.props
    countryCode && this.onCountryChange(countryCode)
  }

  clearInput = () => {
    this.setState({ value: '' })
  }

  handleChange = event => {
    const { value } = this.sanitize(event.target.value)
    const { type, value: stateValue } = this.state
    if (
      type === 'phone' ||
      type === 'number' ||
      type === 'otp' ||
      type === 'integer'
    ) {
      if (
        (stateValue || type === 'otp') &&
        stateValue &&
        stateValue.toString().length === 1 &&
        !this.numCheck(value)
      ) {
        this.sendChange('')
        return this.setState({
          value: ''
        })
      }
      if (value !== '' && !this.numCheck(value)) {
        return
      }
    }
    let finalValue = value
    if (value === 0) {
      finalValue = 0
    } else {
      finalValue = finalValue || ''
    }
    this.setState({ value: finalValue })
    this.sendChange(finalValue)
  }

  sendChange = (value, immediate = false) => {
    const { onChange } = this.props
    if (this.props.controlled || immediate) {
      onChange && onChange(value)
    } else {
      onChange && window.eventDebounce(() => onChange(value), debounceDelay)
    }
  }

  onBlur = () => {
    if (this.state.type === 'date' && this.state.value.length === 0) {
      this.setState({ type: 'dates' })
    }
    const { onChange, onBlur } = this.props
    const { value } = this.state
    onChange && onChange(value)
    onBlur && onBlur(value)
  }

  onFocus = () => {
    this.props.onFocus && this.props.onFocus()
  }

  sanitize = value => {
    const { type, phoneLimit = 10 } = this.state
    const { otpMaxLength: limit = 4 } = this.props
    const len = value.length
    const ch = value.toString().slice(len - 1, len)
    const slicedValue = value.toString().slice(0, len - 1)
    switch (type) {
      case 'number':
        value = this.numCheck(value) ? value : ''
        break
      case 'name':
        value = this.nameCheck(ch) ? value.toString().slice(0, 60) : slicedValue
        break
      case 'annexure':
        value = this.annexureCheck(ch)
          ? value.toString().slice(0, 60)
          : slicedValue
        break
      case 'integer':
        value = parseInt(value).toString()
        break
      case 'phone':
        value = this.numCheck(value)
          ? value.toString().slice(0, phoneLimit)
          : ''
        break
      case 'otp':
        value = this.numCheck(value) ? value.toString().slice(0, limit) : ''
        break
      default:
        break
    }
    return {
      type,
      value
    }
  }

  numCheck = value => {
    if (value === 0) {
      return true
    }
    return value && !isNaN(value)
  }

  nameCheck = value => {
    if (/^[a-zA-Z . + ( ) & ,]{1,60}$/.test(value.toString())) {
      return value && true
    }
  }

  annexureCheck = value => {
    if (/^[a-zA-Z . + ( ) & ,]{1,60}$/.test(value.toString())) {
      return value && true
    }
  }

  typeConvert = type => {
    const types = {
      otp: 'number',
      phone: 'number',
      integer: 'number'
    }
    return types[type] || type
  }

  onCountryTextChange = val => {
    const value = val.target.value
    window.eventDebounce(() => {
      this.onCountryChange(value)
    }, debounceDelay)
  }

  onCountryBlur = () => {
    this.props.onCountryCodeChange &&
      this.props.onCountryCodeChange({
        country: this.state.country,
        val: `+${this.state.countryCode}`
      })
  }

  onCountryChange = val => {
    const { onCountryCodeChange } = this.props
    this.setState({
      countryCodeError: null,
      country: ''
    })
    if (!val && this.props.id === 'owner_phone') {
      this.setState({
        countryCodeError: 'Please enter your country code'
      })
    }
    let country = ''
    CountryCodes.map(c => {
      const shortText = c.shortText || ''
      if (shortText.replace(' ', '') === `+${val}`) {
        country = c.text
      }
    })

    if (val && !country && this.props.id === 'owner_phone') {
      this.setState({
        countryCodeError: 'Please enter a valid country code'
      })
    }
    this.setState({
      countryCode: val,
      country
    })
    onCountryCodeChange &&
      onCountryCodeChange({
        country: this.state.country,
        val: `+${this.state.countryCode}`
      })
  }

  onKeyUp = e => {
    const { onEnter, onKeyPress } = this.props
    onKeyPress && onKeyPress()
    switch (e.which) {
      case 13: // enter
        this.sendChange(this.state.value, true)
        onEnter &&
          setTimeout(() => {
            this.refs.inputBox && this.refs.inputBox.blur()
            onEnter(this.state.value)
          }, debounceDelay + 10)
        break
      default:
        break
    }
  }

  render () {
    const {
      value,
      label,
      type,
      placeholder,
      className = '',
      id = '',
      countryCodeError,
      country
    } = this.state
    const {
      error,
      name,
      readonly,
      selectCountryCodes,
      autoFocus,
      countryCode = '1',
      icon = null,
      loader = null,
      defaultValue,
      fixLabel
    } = this.props

    let classes = `inputContainer`
    if (error) classes += ' errorContainer'
    if (type === 'phone' && !selectCountryCodes) classes += ' countryCode'
    if (selectCountryCodes) classes += ' selectCountryCodes right'
    if (selectCountryCodes) classes += ' selectCountryCodes right'
    if (className) classes += ` ${className}`

    return (
      <div className={classes}>
        {country ? (
          <span className='countrySelected subtext'>{country}</span>
        ) : null}
        {icon}
        {selectCountryCodes ? (
          <React.Fragment>
            <span className='plusSign'>+</span>
            <input
              defaultValue={countryCode}
              type='number'
              className='countryCodeBox'
              maxLength={3}
              onChange={this.onCountryTextChange}
              onBlur={this.onCountryBlur}
            />
          </React.Fragment>
        ) : null}
        <input
          ref='inputBox'
          id={id}
          placeholder={placeholder}
          type={this.typeConvert(type)}
          value={value}
          onChange={this.handleChange}
          onFocus={() => this.focus(type)}
          onBlur={this.onBlur}
          name={name}
          readOnly={readonly}
          onKeyUp={this.onKeyUp}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
        />
        <div
          className={classNames('floatingLabel', {
            moveRight: type === 'phone',
            fixLabel: value && fixLabel
          })}
        >
          {label}
        </div>
        {error ? <span className='error'>{error}</span> : null}
        {countryCodeError ? (
          <span className='error'>{countryCodeError}</span>
        ) : null}
        {loader}
      </div>
    )
  }
}

export default InputBox
