/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import PropTypes from 'prop-types'
import s from './styles.js'
import TickBulletin from '../../../Components/TickBulletin'
import BarButton from '../../../Components/BarButton'
import LiveDemoPopup from '../../../Components/LiveDemoPopup'
import AppStoreButton from '../../../Components/AppStoreButton'

const DATA = [
  'One click listing on major platforms',
  'Online tenant application forms',
  'Tenant background verification',
  'Video based Move-in/out recordkeeping',
  'Online rent collection (ACH and Cards)',
  'Maintenance and expense history ',
  'Tenant behaviour reported to RentKarma ',
  'Unlimited file storage (Photos, videos, documents)'
]

const PM_CARD_DATA = {
  highlite: false,
  title: '$14 per unit + $2 per additional unit / month',
  description: `Renters pay $24.99 for background checks and no minimum billing
  (Payment via bank account is free)`
}

const LL_CARD_DATA = {
  highlite: true,
  title: 'MIMO is FREE for Landlords',
  description: `$25 verification fee per tenant + 2% fee for Credit/Debit cards.
  ACH bank transfer is FREE.`
}

const BottomCard = ({ data, show }) => {
  if (!show) return null
  return (
    <article css={s.bottomWrapper}>
      <article css={s.bottomView(data.highlite)}>
        <article css={s.bottomTitle}>{data.title}</article>
      </article>
      <article css={s.description}>{data.description}</article>
    </article>
  )
}

const FeatureDetails = ({ currentView, selectedLink }) => (
  <article css={s.container}>
    {(!selectedLink || selectedLink === 'left_link') &&
      DATA.map(item => <TickBulletin text={item} />)}
    <BottomCard
      show={!selectedLink || selectedLink !== 'left_link'}
      data={currentView === 'land_lord' ? LL_CARD_DATA : PM_CARD_DATA}
    />
    <articlec css={s.buttonWrapper}>
      <AppStoreButton />
      {/* <LiveDemoPopup text='Request live demo' small /> */}
    </articlec>
  </article>
)

export default FeatureDetails

/* Documentation Section */

FeatureDetails.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

FeatureDetails.defaultProps = {}
