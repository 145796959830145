const updateAdaptive = callback => {
  // 0 - Desktop (default)
  // 1 - Mobile
  if (window.innerWidth < 981 || window.outerWidth < 981) {
    if (window.globals) {
      window.globals.device = 1
    }
  } else {
    if (window.globals) {
      window.globals.device = 0
    }
  }
  callback && callback(window.globals.device)
}

updateAdaptive()

function listenToKeyPressListener (event) {
  const key = event.key // "a", "1", "Shift", etc.
  listenToKeyPressCallback && listenToKeyPressCallback(key)
}

let listenToKeyPressCallback = null

const listenToKeyPress = (action = 'add', callback) => {
  listenToKeyPressCallback = callback
  if (action === 'remove' && listenToKeyPressListener) {
    document.removeEventListener('keydown', listenToKeyPressListener)
    return
  }
  document.addEventListener('keydown', listenToKeyPressListener)
}

export { listenToKeyPress, updateAdaptive }
